import React, { useEffect, useState } from "react";
import "./ButtonSupport.css";
import Tg from "../../assets/svg/telegram.svg";

export const ButtonSupport = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleClick = () => {
    window.open("https://t.me/neyrotalim_support", "_blank");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="support" onClick={handleClick}>
      <div className="support-icon">
        <img src={Tg} alt="telegram" />
      </div>
      {!isMobile && <div className="support-title">Поддержка</div>}
    </div>
  );
};
