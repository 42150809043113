// client/src/pages/AdminPage/AdminPage.js
import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./AdminPage.css";

const AdminPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [error, setError] = useState("");

  // Задайте ваш пароль здесь
  const ADMIN_PASSWORD = "14785963"; // Замените на ваш пароль

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (passwordInput === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
      setError("");
    } else {
      setError("Неверный пароль. Попробуйте снова.");
    }
  };

  return (
    <div className="admin-page">
      {!isAuthenticated ? (
        <div className="admin-login-container">
          <h2>Вход в Админ-панель</h2>
          <form onSubmit={handlePasswordSubmit} className="admin-login-form">
            <input
              type="password"
              placeholder="Введите пароль"
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              required
              className="admin-password-input"
            />
            <button type="submit" className="admin-login-button">
              Войти
            </button>
          </form>
          {error && <p className="admin-error">{error}</p>}
        </div>
      ) : (
        <>
          <nav className="admin-nav">
            <ul>
              <li>
                <Link to="categories">Управление категориями</Link>
              </li>
              <li>
                <Link to="courses">Управление курсами</Link>
              </li>
              <li>
                <Link to="users">Управление пользователями</Link>
              </li>
              <li>
                <Link to="analytics">Аналитика</Link> 
              </li>
            </ul>
          </nav>
          <div className="admin-content">
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};

export default AdminPage;
