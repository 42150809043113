import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Импортируем useNavigate
import "./CardGetGift.css";
import Logo from "../../assets/svg/logo.svg";
import { PhoneInput } from "../../components";

export const CardGetGift = () => {
  const [phone, setPhone] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate(); // Инициализируем navigate

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Функция обработки клика на кнопку "Получить подарок"
  const handleGetGift = () => {
    navigate("/sign-in", { state: { autoRegister: true } }); // Передаём состояние для автоматического открытия регистрации
  };

  return (
    <div className="card-gift">
      <div className="card-gift-left-side">
        <div className="card-gift-top-left">
          <img className="card-gift-logo" src={Logo} alt="" />
          <div className="card-gift-left-side-title">
            Начни свой путь к образованию
          </div>
          <ul className="list-revolution card-gift-list">
            <li>
              100% возврат денежных средств, если курс не оправдал ожиданий
            </li>
          </ul>
        </div>
        {/* 
        {!isMobile && (
          <div className="card-gift-sales-container">
            <div className="card-gift-sales-card">—50%</div>
            <div className="card-gift-sales-card-title">
              Только сейчас — скидка 50%
            </div>
          </div>
        )}
        */}
      </div>
      <div className="card-gift-right-side">
        <div className="card-gift-inputs-container">
          <input className="card-gift-input" placeholder="Имя и Фамилия" />
          <PhoneInput phone={phone} setPhone={setPhone} />
          <input className="card-gift-input" placeholder="Пароль" />
        </div>
        <div className="card-gift-button-container">
          <div className="card-gift-button" onClick={handleGetGift}>
            Зарегистрироваться
          </div>
          <div className="card-gift-subtitle">
            Нажимая 'Зарегистрироваться', вы принимаете условия{" "}
            <a href="/privacy_policy.pdf" download className="privacy-link">
              политики конфиденциальности
            </a>
            .
          </div>
        </div>
      </div>
      {/*
      {isMobile && (
        <div className="card-gift-sales-container">
          <div className="card-gift-sales-card">—50%</div>
          <div className="card-gift-sales-card-title">
            Только сейчас — скидка 50%
          </div>
        </div>
      )}
      */}
    </div>
  );
};
