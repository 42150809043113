// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.04em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 40px;
}

.close-modal img {
    cursor: pointer;

}

.close-modal {
  height: 40px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.modal-content.exit {
  max-width: max-content;
}

.modal-inputs {
  display: flex;
  width: 100%;
  gap: 20px;
}

.modal-inputs .phone-input-container {
  width: 100%;
}

.btn-modal {
  border: none;
  border-radius: 16px;
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #000;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 20px;
  margin-top: 20px;
  transition: all 0.3s ease-in;
}

.btn-modal:hover {
  opacity: 0.9;
}

@media screen and (max-width: 768px) {
    .modal-inputs {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/UI/CustomExitModal/CustomExitModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,kBAAkB;EAClB,kCAAkC;EAClC,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;IACI,eAAe;;AAEnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,SAAS;AACX;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,kCAAkC;EAClC,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;AACd;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".modal-title {\n  font-size: 40px;\n  font-weight: 600;\n  line-height: 40px;\n  letter-spacing: -0.04em;\n  text-align: center;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  margin-bottom: 40px;\n}\n\n.close-modal img {\n    cursor: pointer;\n\n}\n\n.close-modal {\n  height: 40px;\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n}\n\n.modal-content.exit {\n  max-width: max-content;\n}\n\n.modal-inputs {\n  display: flex;\n  width: 100%;\n  gap: 20px;\n}\n\n.modal-inputs .phone-input-container {\n  width: 100%;\n}\n\n.btn-modal {\n  border: none;\n  border-radius: 16px;\n  padding: 30px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  background: #000;\n  color: white;\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: center;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  margin-bottom: 20px;\n  margin-top: 20px;\n  transition: all 0.3s ease-in;\n}\n\n.btn-modal:hover {\n  opacity: 0.9;\n}\n\n@media screen and (max-width: 768px) {\n    .modal-inputs {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
