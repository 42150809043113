import React, { useContext, useState, useEffect } from 'react';
import './CurrentCourseCard.css';
import Modal from 'react-modal';
import LikeIcon from '../../assets/svg/like.svg';
import UnlikeIcon from '../../assets/svg/unlike.svg';
import Download from '../../assets/svg/download.svg';
import { AuthContext } from '../../context/AuthContext';
import { likeCourse, unlikeCourse } from '../../api';

export const CurrentCourseCard = ({ item, setVisibleModal }) => {
  const { authToken, user, setUser } = useContext(AuthContext);
  const [isLiked, setIsLiked] = useState(false);
  const [isBought, setIsBought] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);

  useEffect(() => {
    if (user && user.likes) {
      setIsLiked(user.likes.includes(item._id));
    }
    if (user && user.courses) {
      setIsBought(user.courses.includes(item._id));
    }
  }, [user, item._id]);

  const handlePaymentRedirect = () => {
    // Формирование URL для GET-запроса на оплату
    const merchantId = '67232a7de51de1c6a3a73f8f';
    const amountInTiyins = item.price;
    const callbackUrl = 'https://neyrotalim.com/profile'; // URL возврата после оплаты
    const encodedUrl = btoa(`m=${merchantId};ac.order_id=${item._id};a=${amountInTiyins};l=ru;c=${callbackUrl}`);
    const paymentUrl = `https://checkout.paycom.uz/${encodedUrl}`;

    // Переход на страницу оплаты
    window.location.href = paymentUrl;
  };

  const handleLike = async () => {
    if (!authToken) {
      setIsModalOpen(true);
      return;
    }

    try {
      if (isLiked) {
        const data = await unlikeCourse(authToken, item._id);
        setUser((prevUser) => ({
          ...prevUser,
          likes: data.likes,
        }));
        setIsLiked(false);
      } else {
        const data = await likeCourse(authToken, item._id);
        setUser((prevUser) => ({
          ...prevUser,
          likes: data.likes,
        }));
        setIsLiked(true);
      }
    } catch (error) {
      alert(error.error || 'Произошла ошибка при обработке лайка.');
    }
  };

  const handleDownload = () => {
    if (item.pdf) {
      window.open(item.pdf, '_blank');
    } else {
      alert('PDF файл недоступен.');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeBuyModal = () => {
    setIsBuyModalOpen(false);
  };

  return (
    <div className="current-course-card">
      <div className="current-course-card-img">
        <img className="img" src={item.image} alt={item.title} />
        <img className="img-blur" src={item.image} alt={item.title} />
      </div>
      <div className="current-course-card-body">
        <div className="current-course-card-title">{item.title}</div>
        <div className="current-course-card-category">
          <strong>Kategoriyalar:</strong>{' '}
          {item.categories && item.categories.length > 0
            ? item.categories.map((cat) => cat.name).join(', ')
            : 'Нет категорий'}
        </div>

        <div className="current-course-card-description">
          {item.description && <p>{item.description}</p>}
        </div>

        <div className="current-course-card-btns-container">
          <div className="btn-buy" onClick={!isBought ? handlePaymentRedirect : handleDownload}>
            {isBought && <img src={Download} alt="Download" />}
            {isBought ? 'Скачать PDF' : `Sotib olish ${item.price} UZS`}
          </div>
          <div className="btn-like" onClick={handleLike}>
            <img src={isLiked ? UnlikeIcon : LikeIcon} alt="like" />
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Login Required"
        className="modal-content modal-content-custom"
        overlayClassName="modal-overlay modal-overlay-custom"
      >
        <div className="modal-header">
          <div className="modal-header-text">Iltimos, kursni yoqtirish uchun tizimga kiring.</div>
        </div>
        <div className="modal-message">
          <button onClick={closeModal} className="modal-close-button modal-close-button-custom">
            Close
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isBuyModalOpen}
        onRequestClose={closeBuyModal}
        contentLabel="Login Required to Buy"
        className="modal-content modal-content-custom"
        overlayClassName="modal-overlay modal-overlay-custom"
      >
        <div className="modal-header">
          <div className="modal-header-text">Kursni sotib olish uchun tizimga kiring.</div>
        </div>
        <div className="modal-message">
          <button onClick={closeBuyModal} className="modal-close-button modal-close-button-custom">
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};
