// client/src/index.js

// 1. Импортируем setupConsole.js первым
import "./setupConsole";

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import "./index.css";

// 2. Получаем контейнер для рендеринга
const container = document.getElementById("root");
// 3. Создаем корневой элемент с помощью createRoot
const root = createRoot(container);

// 4. Рендерим приложение без React.StrictMode в продакшен-сборке
const renderApp = () => {
  if (process.env.NODE_ENV === "production") {
    root.render(
      <AuthProvider>
        <App />
      </AuthProvider>
    );
  } else {
    // В режиме разработки используем React.StrictMode для отладки
    root.render(
      <React.StrictMode>
        <AuthProvider>
          <App />
        </AuthProvider>
      </React.StrictMode>
    );
  }
};

renderApp();
