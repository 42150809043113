import React, { useEffect, useState } from "react";
import "./PhoneInput.css";

export const PhoneInput = ({ phone, setPhone }) => {
  const [countryCode, setCountryCode] = useState("+7"); // "+7" для России, "+998" для Узбекистана
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Сбрасываем номер телефона при смене кода страны
  useEffect(() => {
    setPhone("");
  }, [countryCode]);

  const formatPhone = (input) => {
    const digits = input.replace(/\D/g, "");
    if (!digits) return "";

    let formatted = digits;

    if (countryCode === "+7") {
      formatted = formatted.substring(0, 10); // Максимум 10 цифр после кода страны

      formatted = formatted.replace(
        /(\d{3})(\d{0,3})(\d{0,2})(\d{0,2})/,
        (_, p1, p2, p3, p4) =>
          `${p1}${p2 ? ` ${p2}` : ""}${p3 ? `-${p3}` : ""}${p4 ? `-${p4}` : ""}`
      );
    } else if (countryCode === "+998") {
      formatted = formatted.substring(0, 9); // Максимум 9 цифр после кода страны

      formatted = formatted.replace(
        /(\d{2})(\d{0,3})(\d{0,2})(\d{0,2})/,
        (_, p1, p2, p3, p4) =>
          `${p1}${p2 ? ` ${p2}` : ""}${p3 ? `-${p3}` : ""}${p4 ? `-${p4}` : ""}`
      );
    }

    return formatted;
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectCountryCode = (code) => {
    setCountryCode(code);
    setIsDropdownOpen(false);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatPhone(inputValue);
    setPhone(formattedValue);
  };

  return (
    <div className="phone-input-container">
      <div className="country-code-select" onClick={toggleDropdown}>
        {countryCode}
        <span className={`arrow ${isDropdownOpen ? "up" : "down"}`}></span>
      </div>
      <input
        type="text"
        value={phone}
        onChange={handleInputChange}
        placeholder={countryCode === "+7" ? "777 777-77-77" : "77 777-77-77"}
        maxLength={countryCode === "+7" ? 13 : 12}
        className="card-gift-input phone-input"
      />
      {isDropdownOpen && (
        <div className="country-code-dropdown">
          <div
            className="country-code-option"
            onClick={() => selectCountryCode("+7")}
          >
            +7
          </div>
          <div
            className="country-code-option"
            onClick={() => selectCountryCode("+998")}
          >
            +998
          </div>
        </div>
      )}
    </div>
  );
};
