import React from "react";
import "./SubscriptionCard.css";
import logo from "../../assets/svg/logo-plus.svg";

export const SubscriptionCard = () => {
  return (
    <div className="subscription-card">
      <div className="subscription-card-logo">
        <img src={logo} alt="" />
      </div>
      <div className="subscription-card-container">
        <div className="subscription-card-container-title">Этот курс входит в подписку</div>
        <div className="subscription-card-action-button">
          <div className="subscription-card-action-button-title">
            Оформить NeyroTalim+ за <span>60 000</span> 12 000 UZS
          </div>
          <div className="subscription-card-sale-card">-80%</div>
        </div>
      </div>
    </div>
  );
};
