// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.curses-categories-item {
  padding: 7px 8px 9px 8px;
  border-radius: 8px;
  border: 1px solid #00000033;
  cursor: pointer;
  transition: 0.9s easy;
}

.curses-categories-item:hover {
  background-color: rgb(221, 221, 221);
}

@media screen and (max-width: 425px) {
  .curses-categories-item {
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CoursesCard/CoursesCard.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,2BAA2B;EAC3B,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".curses-categories-item {\n  padding: 7px 8px 9px 8px;\n  border-radius: 8px;\n  border: 1px solid #00000033;\n  cursor: pointer;\n  transition: 0.9s easy;\n}\n\n.curses-categories-item:hover {\n  background-color: rgb(221, 221, 221);\n}\n\n@media screen and (max-width: 425px) {\n  .curses-categories-item {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
