// client/src/pages/Header.js

import React, { useContext, useEffect, useState, useRef } from "react";
import Search from "../../assets/svg/Subtract.svg";
import "./Header.css";
import User from "../../assets/svg/User.svg";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Logo from "../../assets/svg/logo.svg";
import { searchCourses } from "../../api"; // Импорт функции для поиска курсов

export const Header = () => {
  const navigate = useNavigate(); // Инициализация navigate
  const { user, logout } = useContext(AuthContext);
  const isAuth = !!user; // Преобразуем объект пользователя в булево значение

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [searchInput, setSearchInput] = useState(""); // Состояние для ввода поиска
  const [searchResults, setSearchResults] = useState([]); // Состояние для результатов поиска
  const searchResultsRef = useRef(null); // Для обработки кликов вне области результатов

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleGoToHome = () => navigate("/");
  const handleGoToSignIn = () => navigate("/sign-in");
  const handleGoToProfile = () => navigate("/profile");
  const handleLogout = () => {
    logout();
    navigate("/sign-in");
  };

  // Обработчик изменения ввода в поле поиска
  const handleSearchInputChange = async (e) => {
    const value = e.target.value;
    setSearchInput(value);

    if (value.trim() === "") {
      setSearchResults([]);
      return;
    }

    try {
      const results = await searchCourses(value);
      setSearchResults(results);
    } catch (error) {
      console.error("Ошибка при поиске курсов:", error);
    }
  };

  // Функция для перехода на страницу курса
  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
    setSearchInput("");
    setSearchResults([]);
  };

  // Обработка клика вне области результатов поиска
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSearchResults([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="header-sign-in">
      <div className="header-sign-in-container">
        {isMobile ? (
          <>
            <div className="logo-btn-container">
              <div
                className="header-sign-in-logo-container"
                onClick={handleGoToHome}
              >
                <img className="header-sign-in-logo" src={Logo} alt="logo" />
              </div>
              {isAuth ? (
                <div className="user-menu">
                  <img
                    onClick={handleGoToProfile}
                    className="user-profile-icon"
                    src={User}
                    alt="User Profile"
                  />
                </div>
              ) : (
                <div className="sign-in-button" onClick={handleGoToSignIn}>
                  Зарегистрироваться
                </div>
              )}
            </div>

            <div className="search-container" ref={searchResultsRef}>
              <img className="input-icon" src={Search} alt="Search Icon" />
              <input
                className="search-container-input"
                placeholder="Поиск курсов…"
                type="text"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              {searchInput.trim() !== "" && (
                <div className="search-results-window">
                  {searchResults.length > 0 ? (
                    searchResults.map((course) => (
                      <div
                        key={course._id}
                        className="search-result-item"
                        onClick={() => handleCourseClick(course._id)}
                      >
                        <img src={course.image} alt={course.title} />
                        <div>{course.title}</div>
                      </div>
                    ))
                  ) : (
                    <div className="no-results-message">Курсы не найдены</div>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {/* Логотип */}
            <div
              className="header-sign-in-logo-container"
              onClick={handleGoToHome}
            >
              <img className="header-sign-in-logo" src={Logo} alt="logo" />
            </div>

            {/* Поле поиска */}
            <div className="search-container" ref={searchResultsRef}>
              <img className="input-icon" src={Search} alt="Search Icon" />
              <input
                className="search-container-input"
                placeholder="Поиск курсов…"
                type="text"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              {searchInput.trim() !== "" && (
                <div className="search-results-window">
                  {searchResults.length > 0 ? (
                    searchResults.map((course) => (
                      <div
                        key={course._id}
                        className="search-result-item"
                        onClick={() => handleCourseClick(course._id)}
                      >
                        <img src={course.image} alt={course.title} />
                        <div>{course.title}</div>
                      </div>
                    ))
                  ) : (
                    <div className="no-results-message">Курсы не найдены</div>
                  )}
                </div>
              )}
            </div>

            {/* Кнопка регистрации или меню пользователя */}
            {isAuth ? (
              <div className="user-menu">
                <img
                  onClick={handleGoToProfile}
                  className="user-profile-icon"
                  src={User}
                  alt="User Profile"
                />
              </div>
            ) : (
              <div className="sign-in-button" onClick={handleGoToSignIn}>
                Зарегистрироваться
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
