// client/src/pages/AdminPage/CoursesAdminPage.jsx
import React, { useState, useEffect } from 'react';
import { getCourses, addCourse, updateCourse, deleteCourse, getCategories } from '../../api';
import './CoursesAdminPage.css';

const CoursesAdminPage = () => {
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newCourse, setNewCourse] = useState({
    title: '',
    description: '',
    image: null,
    pdf: null,
    audio: null,
    price: '',
    categories: [],
    comments: [],
  });

  const [newCommentName, setNewCommentName] = useState('');
  const [newCommentAge, setNewCommentAge] = useState('');
  const [newCommentText, setNewCommentText] = useState('');
  const [newCommentRating, setNewCommentRating] = useState('');

  const [message, setMessage] = useState('');
  const [editingCourse, setEditingCourse] = useState(null);
  const [editedCourse, setEditedCourse] = useState({
    title: '',
    description: '',
    image: null,
    pdf: null,
    audio: null,
    price: '',
    categories: [],
    comments: [],
  });
  const [editedCommentName, setEditedCommentName] = useState('');
  const [editedCommentAge, setEditedCommentAge] = useState('');
  const [editedCommentText, setEditedCommentText] = useState('');
  const [editedCommentRating, setEditedCommentRating] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);

  useEffect(() => {
    fetchCourses();
    fetchCategories();
  }, []);

  const fetchCourses = async () => {
    try {
      const data = await getCourses();
      setCourses(data);
    } catch (error) {
      setMessage(error.error || 'Не удалось загрузить курсы.');
    }
  };

  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      setCategories(data);
    } catch (error) {
      setMessage(error.error || 'Не удалось загрузить категории.');
    }
  };

  const handleAddCourse = async (e) => {
    e.preventDefault();
    const { title, description, image, pdf, audio, price, categories, comments } = newCourse;
    if (!title.trim() || !image || !price || categories.length === 0) {
      setMessage('Пожалуйста, заполните все обязательные поля.');
      return;
    }

    const priceNumber = parseFloat(price);
    if (isNaN(priceNumber) || priceNumber < 0) {
      setMessage('Цена должна быть положительным числом.');
      return;
    }

    try {
      const response = await addCourse(newCourse);
      setMessage(response.message);
      setNewCourse({
        title: '',
        description: '',
        image: null,
        pdf: null,
        audio: null,
        price: '',
        categories: [],
        comments: [],
      });
      setNewCommentName('');
      setNewCommentText('');
      setNewCommentAge('');
      setNewCommentRating('');
      fetchCourses();
    } catch (error) {
      setMessage(error.error || 'Произошла ошибка при добавлении курса.');
    }
  };

  const handleDelete = async () => {
    if (!courseToDelete) return;
    try {
      const data = await deleteCourse(courseToDelete._id);
      setMessage(data.message);
      setShowDeleteModal(false);
      setCourseToDelete(null);
      fetchCourses();
    } catch (error) {
      setMessage(error.error || 'Не удалось удалить курс.');
    }
  };

  const handleEdit = (course) => {
    setEditingCourse(course);
    setEditedCourse({
      title: course.title || '',
      description: course.description || '',
      image: null,
      pdf: null,
      audio: null,
      price: course.price !== undefined ? course.price.toString() : '',
      categories: (course.categories || []).map((cat) => cat._id),
      comments: course.comments || [],
    });
  };

  const handleUpdate = async () => {
    const { title, description, price, categories } = editedCourse;

    if (!title.trim() || !price || categories.length === 0) {
      setMessage('Пожалуйста, заполните все обязательные поля.');
      return;
    }

    const priceNumber = parseFloat(price);
    if (isNaN(priceNumber) || priceNumber < 0) {
      setMessage('Цена должна быть положительным числом.');
      return;
    }

    try {
      const response = await updateCourse(editingCourse._id, editedCourse);
      setMessage(response.message);
      setEditingCourse(null);
      setEditedCourse({
        title: '',
        description: '',
        image: null,
        pdf: null,
        audio: null,
        price: '',
        categories: [],
        comments: [],
      });
      setEditedCommentName('');
      setEditedCommentText('');
      setEditedCommentAge('');
      setEditedCommentRating('');
      fetchCourses();
    } catch (error) {
      setMessage(error.error || 'Произошла ошибка при обновлении курса.');
    }
  };

  const handleCancelEdit = () => {
    setEditingCourse(null);
    setEditedCourse({
      title: '',
      description: '',
      image: null,
      pdf: null,
      audio: null,
      price: '',
      categories: [],
      comments: [],
    });
  };

  const handleFileChange = (e, setCourse) => {
    const file = e.target.files[0];
    if (file) {
      if (e.target.name === 'image') {
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
        if (!allowedTypes.includes(file.type)) {
          setMessage('Разрешены только изображения (jpeg, jpg, png, gif).');
          setCourse((prev) => ({ ...prev, image: null }));
          return;
        }
        if (file.size > 20 * 1024 * 1024) {
          setMessage('Размер файла не должен превышать 20MB.');
          setCourse((prev) => ({ ...prev, image: null }));
          return;
        }
        setCourse((prev) => ({ ...prev, image: file }));
      } else if (e.target.name === 'pdf') {
        if (file.type !== 'application/pdf') {
          setMessage('Разрешены только PDF-файлы.');
          setCourse((prev) => ({ ...prev, pdf: null }));
          return;
        }
        if (file.size > 20 * 1024 * 1024) {
          setMessage('Размер PDF-файла не должен превышать 20MB.');
          setCourse((prev) => ({ ...prev, pdf: null }));
          return;
        }
        setCourse((prev) => ({ ...prev, pdf: file }));
      } else if (e.target.name === 'audio') {
        const allowedAudioTypes = ['audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/ogg'];
        if (!allowedAudioTypes.includes(file.type)) {
          setMessage('Разрешены только аудиофайлы (mp3, wav, ogg).');
          setCourse((prev) => ({ ...prev, audio: null }));
          return;
        }
        if (file.size > 20 * 1024 * 1024) {
          setMessage('Размер аудиофайла не должен превышать 20MB.');
          setCourse((prev) => ({ ...prev, audio: null }));
          return;
        }
        setCourse((prev) => ({ ...prev, audio: file }));
      }
    }
  };

  const handleCategoryChange = (e, setCourse) => {
    const options = e.target.options;
    const selectedCategories = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedCategories.push(options[i].value);
      }
    }
    setCourse((prev) => ({ ...prev, categories: selectedCategories }));
  };

  const handleAddCommentToNewCourse = () => {
    if (!newCommentName.trim() || !newCommentText.trim() || !newCommentRating) {
      setMessage('Пожалуйста, заполните имя, текст и оценку комментария.');
      return;
    }
    const ratingNum = parseFloat(newCommentRating);
    if (isNaN(ratingNum) || ratingNum < 0 || ratingNum > 5) {
      setMessage('Оценка комментария должна быть числом от 0 до 5.');
      return;
    }

    let ageNum = undefined;
    if (newCommentAge.trim()) {
      ageNum = parseInt(newCommentAge.trim(), 10);
      if (isNaN(ageNum) || ageNum <= 0) {
        setMessage('Возраст должен быть положительным числом.');
        return;
      }
    }

    setNewCourse((prev) => ({
      ...prev,
      comments: [...prev.comments, { name: newCommentName.trim(), age: ageNum, text: newCommentText.trim(), rating: ratingNum }]
    }));
    setNewCommentName('');
    setNewCommentText('');
    setNewCommentAge('');
    setNewCommentRating('');
  };

  const handleAddCommentToEditedCourse = () => {
    if (!editedCommentName.trim() || !editedCommentText.trim() || !editedCommentRating) {
      setMessage('Пожалуйста, заполните имя, текст и оценку комментария.');
      return;
    }
    const ratingNum = parseFloat(editedCommentRating);
    if (isNaN(ratingNum) || ratingNum < 0 || ratingNum > 5) {
      setMessage('Оценка комментария должна быть числом от 0 до 5.');
      return;
    }

    let ageNum = undefined;
    if (editedCommentAge.trim()) {
      ageNum = parseInt(editedCommentAge.trim(), 10);
      if (isNaN(ageNum) || ageNum <= 0) {
        setMessage('Возраст должен быть положительным числом.');
        return;
      }
    }

    setEditedCourse((prev) => ({
      ...prev,
      comments: [...prev.comments, { name: editedCommentName.trim(), age: ageNum, text: editedCommentText.trim(), rating: ratingNum }]
    }));
    setEditedCommentName('');
    setEditedCommentText('');
    setEditedCommentAge('');
    setEditedCommentRating('');
  };

  return (
    <div className="courses-admin-page">
      <h2>Управление курсами</h2>

      <form onSubmit={handleAddCourse} className="add-course-form">
        <h3>Добавить новый курс</h3>

        {/* Поля для курса */}
        <div className="form-group">
          <label htmlFor="title">Название курса:</label>
          <input
            type="text"
            id="title"
            value={newCourse.title}
            onChange={(e) => setNewCourse({ ...newCourse, title: e.target.value })}
            required
            placeholder="Введите название курса"
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Описание курса (обязательно):</label>
          <textarea
            id="description"
            value={newCourse.description}
            onChange={(e) => setNewCourse({ ...newCourse, description: e.target.value })}
            placeholder="Введите описание курса"
          ></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="image">Изображение курса:</label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={(e) => handleFileChange(e, setNewCourse)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="pdf">PDF-файл курса:</label>
          <input
            type="file"
            id="pdf"
            name="pdf"
            accept="application/pdf"
            onChange={(e) => handleFileChange(e, setNewCourse)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="audio">Аудиофайл курса (необязательно):</label>
          <input
            type="file"
            id="audio"
            name="audio"
            accept="audio/*"
            onChange={(e) => handleFileChange(e, setNewCourse)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="price">Цена:</label>
          <input
            type="number"
            id="price"
            step="0.01"
            min="0"
            value={newCourse.price}
            onChange={(e) => setNewCourse({ ...newCourse, price: e.target.value })}
            required
            placeholder="Введите цену курса"
          />
        </div>

        <div className="form-group">
          <label htmlFor="categories">Категории:</label>
          <select
            id="categories"
            multiple
            value={newCourse.categories}
            onChange={(e) => handleCategoryChange(e, setNewCourse)}
            required
          >
            {categories.map((cat) => (
              <option key={cat._id} value={cat._id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>

        {/* Добавление комментариев (с возрастом) */}
        <div className="form-group">
          <label>Комментарии к курсу (необязательно):</label>
          <div className="comment-form-group">
            <input
              type="text"
              placeholder="Имя"
              value={newCommentName}
              onChange={(e) => setNewCommentName(e.target.value)}
            />
            <input
              type="number"
              placeholder="Возраст"
              value={newCommentAge}
              onChange={(e) => setNewCommentAge(e.target.value)}
            />
            <input
              type="text"
              placeholder="Текст комментария"
              value={newCommentText}
              onChange={(e) => setNewCommentText(e.target.value)}
            />
            <input
              type="number"
              step="0.1"
              min="0"
              max="5"
              placeholder="Оценка (0-5)"
              value={newCommentRating}
              onChange={(e) => setNewCommentRating(e.target.value)}
            />
            <button type="button" onClick={handleAddCommentToNewCourse}>
              Добавить комментарий
            </button>
          </div>
          {newCourse.comments.length > 0 && (
            <ul>
              {newCourse.comments.map((c, i) => (
                <li key={i}>
                  <strong>{c.name}{c.age ? `, ${c.age}` : ''}</strong> (оценка: {c.rating}): {c.text}
                </li>
              ))}
            </ul>
          )}
        </div>

        <button type="submit" className="btn btn-primary">Добавить курс</button>
      </form>

      {message && <div className="notification">{message}</div>}

      <h3>Существующие курсы:</h3>
      <ul className="courses-list">
        {courses.map((course) => (
          <li key={course._id} className="course-item">
            {editingCourse && editingCourse._id === course._id ? (
              <div className="edit-form">
                {/* Редактирование курса */}
                <div className="form-group">
                  <label htmlFor={`edit-title-${course._id}`}>Название курса:</label>
                  <input
                    type="text"
                    id={`edit-title-${course._id}`}
                    value={editedCourse.title}
                    onChange={(e) =>
                      setEditedCourse({ ...editedCourse, title: e.target.value })
                    }
                    required
                    placeholder="Введите название курса"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor={`edit-description-${course._id}`}>Описание курса (обязательно):</label>
                  <textarea
                    id={`edit-description-${course._id}`}
                    value={editedCourse.description}
                    onChange={(e) =>
                      setEditedCourse({ ...editedCourse, description: e.target.value })
                    }
                    placeholder="Введите описание курса"
                  ></textarea>
                </div>

                <div className="form-group">
                  <label htmlFor={`edit-image-${course._id}`}>Изображение курса:</label>
                  <input
                    type="file"
                    id={`edit-image-${course._id}`}
                    name="image"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, setEditedCourse)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor={`edit-pdf-${course._id}`}>PDF-файл курса:</label>
                  <input
                    type="file"
                    id={`edit-pdf-${course._id}`}
                    name="pdf"
                    accept="application/pdf"
                    onChange={(e) => handleFileChange(e, setEditedCourse)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor={`edit-audio-${course._id}`}>Аудиофайл курса (необязательно):</label>
                  <input
                    type="file"
                    id={`edit-audio-${course._id}`}
                    name="audio"
                    accept="audio/*"
                    onChange={(e) => handleFileChange(e, setEditedCourse)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor={`edit-price-${course._id}`}>Цена:</label>
                  <input
                    type="number"
                    id={`edit-price-${course._id}`}
                    step="0.01"
                    min="0"
                    value={editedCourse.price}
                    onChange={(e) =>
                      setEditedCourse({ ...editedCourse, price: e.target.value })
                    }
                    required
                    placeholder="Введите цену курса"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor={`edit-categories-${course._id}`}>Категории:</label>
                  <select
                    id={`edit-categories-${course._id}`}
                    multiple
                    value={editedCourse.categories}
                    onChange={(e) => handleCategoryChange(e, setEditedCourse)}
                    required
                  >
                    {categories.map((cat) => (
                      <option key={cat._id} value={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>Комментарии к курсу (необязательно):</label>
                  <div className="comment-form-group">
                    <input
                      type="text"
                      placeholder="Имя"
                      value={editedCommentName}
                      onChange={(e) => setEditedCommentName(e.target.value)}
                    />
                    <input
                      type="number"
                      placeholder="Возраст"
                      value={editedCommentAge}
                      onChange={(e) => setEditedCommentAge(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Текст комментария"
                      value={editedCommentText}
                      onChange={(e) => setEditedCommentText(e.target.value)}
                    />
                    <input
                      type="number"
                      step="0.1"
                      min="0"
                      max="5"
                      placeholder="Оценка (0-5)"
                      value={editedCommentRating}
                      onChange={(e) => setEditedCommentRating(e.target.value)}
                    />
                    <button type="button" onClick={handleAddCommentToEditedCourse}>
                      Добавить комментарий
                    </button>
                  </div>
                  {editedCourse.comments.length > 0 && (
                    <ul>
                      {editedCourse.comments.map((c, i) => (
                        <li key={i}>
                          <strong>{c.name}{c.age ? `, ${c.age}` : ''}</strong> (оценка: {c.rating}): {c.text}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="edit-buttons">
                  <button type="button" className="btn btn-success" onClick={handleUpdate}>
                    Сохранить
                  </button>
                  <button type="button" className="btn btn-secondary" onClick={handleCancelEdit}>
                    Отмена
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="course-details">
                  <h4>{course.title}</h4>
                  {course.image && (
                    <img src={course.image} alt={course.title} className="course-image" />
                  )}
                  <p>
                    <strong>Описание:</strong> {course.description || 'Описание отсутствует'}
                  </p>
                  <p>
                    <strong>Категории:</strong>{' '}
                    {course.categories && course.categories.length > 0
                      ? course.categories.map((cat) => cat.name).join(', ')
                      : 'Нет категорий'}
                  </p>
                  <p>
                    <strong>Цена:</strong> {course.price}
                  </p>
                  {course.pdf && (
                    <p>
                      <strong>PDF:</strong>{' '}
                      <a href={course.pdf} target="_blank" rel="noopener noreferrer">
                        Скачать PDF
                      </a>
                    </p>
                  )}
                  {course.audio && (
                    <p>
                      <strong>Аудиофайл:</strong>{' '}
                      <audio controls>
                        <source src={course.audio} type="audio/mpeg" />
                        Ваш браузер не поддерживает элемент audio.
                      </audio>
                    </p>
                  )}
                  {course.comments && course.comments.length > 0 && (
                    <div>
                      <strong>Комментарии:</strong>
                      <ul>
                        {course.comments.map((c, i) => (
                          <li key={i}>
                            <strong>{c.name}{c.age ? `, ${c.age}` : ''}</strong> (оценка: {c.rating}): {c.text}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="course-actions">
                  <button type="button" className="btn btn-warning" onClick={() => handleEdit(course)}>
                    Редактировать
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      setShowDeleteModal(true);
                      setCourseToDelete(course);
                    }}
                  >
                    Удалить
                  </button>
                </div>
              </>
            )}
          </li>
        ))}
      </ul>

      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Подтвердите удаление</h3>
            <p>Вы уверены, что хотите удалить курс "{courseToDelete?.title}"?</p>
            <div className="modal-buttons">
              <button className="btn btn-danger" onClick={handleDelete}>Удалить</button>
              <button className="btn btn-secondary" onClick={() => { setShowDeleteModal(false); setCourseToDelete(null); }}>Отмена</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoursesAdminPage;
