import React, { useState } from "react";
import { ReviewCard } from "../../components";
import "./ReviewsContainer.css";

export const ReviewsContainer = ({ reviews }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReviews = () => {
    setIsExpanded((prev) => !prev);
  };

  // Определяем, сколько отзывов показывать
  const visibleReviews = isExpanded ? reviews : reviews.slice(0, 1);
  return (
    <div className="reviews-container">
      <div className="reviews-container-title">Отзывы</div>
      <div className="reviews-container-items">
        {visibleReviews.map((item, index) => {
          return (
            <ReviewCard
              key={`review-${index}`}
              name={item.name}
              age={item.age}
              text={item.text}
              rating={item.rating}
            />
          );
        })}
      </div>
      {reviews.length > 1 && (
        <button className="reviews-toggle-button" onClick={toggleReviews}>
          {isExpanded ? "Скрыть" : "Показать еще"}
        </button>
      )}
    </div>
  );
};
