// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-container {
  display: flex;
  flex-direction: column;
  gap: 49px;
  margin-top: 72px;
}

.price-container-price {
  margin-top: 16px;
}

.price-container-payments {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  gap: 8px;
  margin-top: 24px;
}

.price-container-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.price-container-price {
  font-size: 48px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.cert-title {
  margin-top: 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 208px;
}

@media screen and (max-width: 768px) {
    .price-container {
        margin-top: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/UI/CardPrice/CardPrice.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,kCAAkC;EAClC,8BAA8B;EAC9B,QAAQ;EACR,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,kCAAkC;EAClC,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,kCAAkC;EAClC,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,kCAAkC;EAClC,8BAA8B;EAC9B,YAAY;AACd;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".price-container {\n  display: flex;\n  flex-direction: column;\n  gap: 49px;\n  margin-top: 72px;\n}\n\n.price-container-price {\n  margin-top: 16px;\n}\n\n.price-container-payments {\n  display: flex;\n  align-items: center;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 16px;\n  text-align: left;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  gap: 8px;\n  margin-top: 24px;\n}\n\n.price-container-title {\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 28px;\n  text-align: left;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n}\n\n.price-container-price {\n  font-size: 48px;\n  font-weight: 400;\n  line-height: 40px;\n  text-align: left;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n}\n\n.cert-title {\n  margin-top: 14px;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 16px;\n  text-align: left;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  width: 208px;\n}\n\n@media screen and (max-width: 768px) {\n    .price-container {\n        margin-top: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
