// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page-content {
  width: 86%;
  margin: 0 72px;
}

@media screen and (min-width: 1140px) and (max-width: 1600px) {
  .home-page-content {
    width: 77%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {
  .home-page-content {
    width: 70%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".home-page {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.home-page-content {\n  width: 86%;\n  margin: 0 72px;\n}\n\n@media screen and (min-width: 1140px) and (max-width: 1600px) {\n  .home-page-content {\n    width: 77%;\n  }\n}\n\n@media screen and (min-width: 1600px) and (max-width: 1920px) {\n  .home-page-content {\n    width: 70%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
