// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: center;
}

.payment-method-item {
  background: white;
  padding: 19px;
  box-shadow: 0px 2px 12px 0px #ebe0e9;
  border-radius: 12px;
  margin-top: 34px;
  width: 329px;
}

.modal-content .btn-buy {
  width: 329px;
  margin-top: 198px;
}

@media screen and (max-width: 425px) {
  .payment-method-item {
    width: 75%;
  }

  .modal-content .btn-buy{
    width: 88%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/UI/PaymentModal/PaymentModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,oCAAoC;EACpC,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".modal-title {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 32px;\n  letter-spacing: -0.02em;\n  text-align: center;\n}\n\n.payment-method-item {\n  background: white;\n  padding: 19px;\n  box-shadow: 0px 2px 12px 0px #ebe0e9;\n  border-radius: 12px;\n  margin-top: 34px;\n  width: 329px;\n}\n\n.modal-content .btn-buy {\n  width: 329px;\n  margin-top: 198px;\n}\n\n@media screen and (max-width: 425px) {\n  .payment-method-item {\n    width: 75%;\n  }\n\n  .modal-content .btn-buy{\n    width: 88%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
