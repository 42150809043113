import React, { useEffect, useRef, useState } from "react";
import "./GuestPage.css";
import BgGuest from "../../assets/svg/hero-image.svg";
import {
  CardGetGift,
  CardGuestAction,
  CardPrice,
  CardResults,
  CardRevolution,
  CustomExitModal,
  GuestFooter,
  GuestPageHeader,
} from "../../UI";

const GuestPage = () => {
  const [visibleCards, setVisibleCards] = useState([]); // Отслеживаем видимые карточки
  const cardsRef = useRef([]); // Рефы для карточек
  const [visibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = cardsRef.current.indexOf(entry.target);

          if (entry.isIntersecting) {
            // Добавляем в видимые карточки
            setVisibleCards((prev) => {
              if (!prev.includes(index)) return [...prev, index];
              return prev;
            });
          } else {
            // Убираем из видимых карточек
            setVisibleCards((prev) => prev.filter((i) => i !== index));
          }
        });
      },
      {
        threshold: 0.1, // Минимальный процент видимости
      }
    );

    cardsRef.current.forEach((card) => observer.observe(card));

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    // Устанавливаем таймер на 30 секунд для отображения модального окна
    const timer = setTimeout(() => {
      setVisibleModal(true);
    }, 30000);

    // Очищаем таймер при размонтировании компонента
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="guest-page">
      <div className="guest-page-img-container">
        <img src={BgGuest} alt="" />
      </div>
      <div className="guest-centered-container">
        <GuestPageHeader />
        <div className="big-cards-container">
          {[
            CardGetGift,
            CardRevolution,
            CardResults,
            CardPrice,
            CardGuestAction,
          ].map((CardComponent, index) => (
            <div
              key={index}
              className={`card-wrapper ${
                visibleCards.includes(index) ? "visible" : ""
              }`}
              ref={(el) => (cardsRef.current[index] = el)}
            >
              <CardComponent />
            </div>
          ))}
        </div>
      </div>
      <GuestFooter />
      {visibleModal && (
        <CustomExitModal
          setShowModal={setVisibleModal}
          showModal={visibleModal}
        />
      )}
      {/* <CustomExitModal /> */}
    </div>
  );
};

export default GuestPage;
