import React, { useEffect, useState } from "react";
import "./CardGuestAction.css";
import Logo from "../../assets/svg/logo.svg";
import { useNavigate } from "react-router-dom";

export const CardGuestAction = () => {
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleGoReg = () => navigate("/sign-in");

  return (
    <div className="card-guest-action">
      <div className="card-guest-action-left-side">
        <div className="cga-logo">
          <img src={Logo} alt="" />
        </div>
        <div className="card-guest-action-left-side-title">
        Начни свой путь к образованию
        </div>
        {!isMobile && (
          <div
            className="card-guest-action-left-side-button"
            onClick={handleGoReg}
          >
            Зарегистрироваться
          </div>
        )}
      </div>
      <div className="card-guest-action-right-side">
        <div className="right-title">Гарантия качества</div>
        <div className="right-list">
          <li>Мы уверены в качестве наших курсов, поэтому гарантируем: не понравится — вернем 100% оплаты!</li>
          <li>Контент постоянно обновляется с учетом современных тенденций</li>
          <li>Индивидуальный подход к качеству каждого курса</li>
        </div>
      </div>
      {isMobile && (
        <div
          className="card-guest-action-left-side-button"
          onClick={handleGoReg}
        >
          Регистрироваться
        </div>
      )}
    </div>
  );
};
