// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-courses-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: left;
  margin-bottom: 12px;
  margin-left: 12px;
}

.recent-courses-items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px 9px;
  margin-bottom: 61px;
}
`, "",{"version":3,"sources":["webpack://./src/UI/RecentCourses/RecentCourses.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".recent-courses-title {\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 12px;\n  letter-spacing: 0.1em;\n  text-align: left;\n  margin-bottom: 12px;\n  margin-left: 12px;\n}\n\n.recent-courses-items-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 4px 9px;\n  margin-bottom: 61px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
