// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-page {
  margin: 0 72px;
}

.back-home {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgb(153 153 153);
  margin-top: 41px;
  cursor: pointer;;
}

.back-home img {
  margin-right: 8px;
}

.category-page-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-top: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CategoryPage/CategoryPage.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".category-page {\n  margin: 0 72px;\n}\n\n.back-home {\n  display: flex;\n  align-items: center;\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 16px;\n  letter-spacing: -0.01em;\n  text-align: left;\n  color: rgb(153 153 153);\n  margin-top: 41px;\n  cursor: pointer;;\n}\n\n.back-home img {\n  margin-right: 8px;\n}\n\n.category-page-title {\n  font-size: 40px;\n  font-weight: 700;\n  line-height: 40px;\n  letter-spacing: -0.02em;\n  text-align: left;\n  margin-top: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
