// client/src/setupConsole.js

if (process.env.NODE_ENV === 'production') {
  // Переопределяем console.error и console.warn, оставляя console.log
  console.error = () => {};
  console.warn = () => {};

  // Глобальный обработчик ошибок
  window.onerror = function () {
    // Предотвращаем вывод ошибки на экран
    return true;
  };

  // Глобальный обработчик необработанных промисов
  window.onunhandledrejection = function () {
    // Предотвращаем вывод ошибки на экран
    return true;
  };
}
