// client/src/pages/HomePage/GuestPageHeader.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Импортируем useNavigate
import Logo from "../../assets/svg/logo.svg";
import LogoSm from "../../assets/svg/logo-sm.svg";
import "./GuestPageHeader.css";
import { getCategories, getCourses } from '../../api';
import { sampleSize } from 'lodash'; // Импортируем sampleSize из lodash

// Компонент Card уже определён в вашем коде
const Card = ({ img, title, onClick }) => { // Добавляем onClick как пропс
  return (
    <div className="guest-card-container" onClick={onClick} style={{ cursor: 'pointer' }}> {/* Добавляем onClick и курсор */}
      <div className="guest-card-image-container">
        <img src={img} alt={title} />
        <div className="card-absolute-logo">
          <img src={LogoSm} alt="Logo Small" />
        </div>
      </div>
      <div className="quest-card-title">{title}</div>
    </div>
  );
};

export const GuestPageHeader = () => {
  const navigate = useNavigate(); // Инициализируем useNavigate
  const [categories, setCategories] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [error, setError] = useState('');

  // Функция для получения категорий
  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      setCategories(data);
      if (data.length > 0) {
        // Устанавливаем первую категорию как активную по умолчанию
        setActiveIndex(0);
        fetchCoursesForCategory(data[0]._id);
      }
    } catch (error) {
      setError(error.error || 'Ошибка при получении категорий.');
      console.error('Ошибка при получении категорий:', error);
    }
  };

  // Функция для получения курсов по категории
  const fetchCoursesForCategory = async (categoryId) => {
    try {
      const allCourses = await getCourses();
      // Фильтруем курсы по категории
      const categoryCourses = allCourses.filter(
        (course) =>
          course.categories &&
          course.categories.some((cat) => cat._id === categoryId)
      );

      if (categoryCourses.length > 0) {
        // Выбираем 100 случайных курсов (можно изменить количество по необходимости)
        const selectedCourses = sampleSize(categoryCourses, 1000).map(course => ({
          img: course.image || course.imageUrl || '', // Убедитесь, что поле с изображением правильное
          title: course.title,
          _id: course._id,
        }));
        setCardsData(selectedCourses);
      } else {
        setCardsData([]); // Нет курсов для выбранной категории
      }
    } catch (error) {
      setError(error.error || 'Ошибка при получении курсов.');
      console.error('Ошибка при получении курсов:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleSetActiveIndex = (index, categoryId) => {
    setActiveIndex(index);
    fetchCoursesForCategory(categoryId);
  };

  // Функция для навигации к курсу
  const handleGoToCourse = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  return (
    <div className="guest-header">
      <div className="guest-logo-container">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="guest-header-title">Тысячи курсов по любым темам</div>

      <div className="guest-header-subtitle">
        Наши курсы охватывают основные области развития:
      </div>

      {error && <p className="error-message">{error}</p>}

      <div className="quest-category-container">
        {categories.map((item, index) => {
          return (
            <div
              className={`quest-category-item ${
                activeIndex === index ? "active" : ""
              }`}
              key={`category-guest-${index}`}
              onClick={() => handleSetActiveIndex(index, item._id)}
            >
              {item.name}
            </div>
          );
        })}
      </div>

      <div
        className="quest-cards-container"
        ref={(ref) => {
          if (ref) {
            ref.scrollLeft = ref.scrollWidth / 2 - ref.clientWidth / 2;
          }
        }}
      >
        {cardsData.length > 0 ? (
          cardsData.map((item, index) => {
            return (
              <Card
                key={`cards-${index}`}
                title={item.title}
                img={item.img}
                onClick={() => handleGoToCourse(item._id)} // Передаем функцию навигации
              />
            );
          })
        ) : (
          <p className="no-courses-message">Курстар мавжуд эмас.</p>
        )}
      </div>
    </div>
  );
};
