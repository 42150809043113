// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffffe5;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: absolute;
  background: #F5F0F4;
  height: max-content;
  border-radius: 40px;
  padding: 40px;
  z-index: 99;
}

@media screen and (max-width: 425px) {
  .modal-content {
    padding: 20px;
    width: 77%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalComponent/ModalComponent.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,YAAY;EACZ,aAAa;EACb,qBAAqB;EACrB,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,UAAU;EACZ;AACF","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  width: 100vw;\n  height: 100vh;\n  background: #ffffffe5;\n  z-index: 2;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.modal-content {\n  position: absolute;\n  background: #F5F0F4;\n  height: max-content;\n  border-radius: 40px;\n  padding: 40px;\n  z-index: 99;\n}\n\n@media screen and (max-width: 425px) {\n  .modal-content {\n    padding: 20px;\n    width: 77%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
