import React, { useEffect, useState } from "react";
import "./ReviewCard.css";
import { StarRating } from "../../UI";
import user from "../../assets/svg/user-p.svg";

export const ReviewCard = ({ avatar, name, age, rating, text }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatAge = (ageValue) => {
    // Если возраст не передан или неверный
    if (!ageValue || isNaN(ageValue) || ageValue <= 0) {
      return '';
    }

    // Склоняем слово "год"
    const declension = (number, words) => {
      const cases = [2, 0, 1, 1, 1, 2];
      return words[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    };

    const word = declension(ageValue, ["год", "года", "лет"]);
    return `${ageValue} ${word}`;
  };

  const displayAge = formatAge(age);

  return (
    <div className="review-container-item-container">
      <div className="review-container-item-header">
        <div className="review-container-body-header">
          <div className="review-container-body-header-name">
            {name}
            {/* Если возраст корректен, добавим его */}
            {displayAge 
              ? (isMobile 
                  ? ` · ${displayAge}` 
                  : `, ${displayAge}`
                )
              : ''
            }
          </div>
          <div className="review-container-body-header-stars">
            <StarRating rating={rating} size={isMobile ? 13 : 16} />
          </div>
        </div>
        <div className="review-container-item-container-avatar">
          <img
            className={avatar ? "avatar" : ""}
            src={avatar ? avatar : user}
            alt=""
          />
        </div>
      </div>

      <div className="review-container-body">
        <div className="review-container-body-text">{text}</div>
      </div>
    </div>
  );
};
