// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.star-rating {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stars {
  display: flex;
  gap: 5px;
}

.star-rating p {
  font-family: "Geologica";
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
  padding-top: 4px;
  padding-left: 5px;
}

.empty-star {
  filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.05));
}

.empty-star.stroke {
  stroke: black;
}

/* rating style */
/* .star-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
  }
  
  .stars {
    display: flex;
    gap: 5px;
  }
  
  .star {
    width: 24px;
    height: 24px;
    background-color: lightgray;
    clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
  }
  
  .star.full {
    background-color: black;
  }
  
  .star.half {
    background: linear-gradient(to right, black 50%, lightgray 50%);
  }
  
  .star.empty {
    background-color: lightgray;
  }
  
  .star-rating p {
    margin-top: 8px;
    font-size: 14px;
    color: #333;
  } */
/* rating style */
`, "",{"version":3,"sources":["webpack://./src/UI/StarRating/StarRating.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,kCAAkC;EAClC,8BAA8B;EAC9B,SAAS;EACT,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,aAAa;AACf;;AAEA,iBAAiB;AACjB;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;KA8CK;AACL,iBAAiB","sourcesContent":[".star-rating {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.stars {\n  display: flex;\n  gap: 5px;\n}\n\n.star-rating p {\n  font-family: \"Geologica\";\n  font-size: 12px;\n  font-weight: 300;\n  line-height: 12px;\n  text-align: left;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  margin: 0;\n  padding-top: 4px;\n  padding-left: 5px;\n}\n\n.empty-star {\n  filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.05));\n}\n\n.empty-star.stroke {\n  stroke: black;\n}\n\n/* rating style */\n/* .star-rating {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-family: Arial, sans-serif;\n  }\n  \n  .stars {\n    display: flex;\n    gap: 5px;\n  }\n  \n  .star {\n    width: 24px;\n    height: 24px;\n    background-color: lightgray;\n    clip-path: polygon(\n      50% 0%,\n      61% 35%,\n      98% 35%,\n      68% 57%,\n      79% 91%,\n      50% 70%,\n      21% 91%,\n      32% 57%,\n      2% 35%,\n      39% 35%\n    );\n  }\n  \n  .star.full {\n    background-color: black;\n  }\n  \n  .star.half {\n    background: linear-gradient(to right, black 50%, lightgray 50%);\n  }\n  \n  .star.empty {\n    background-color: lightgray;\n  }\n  \n  .star-rating p {\n    margin-top: 8px;\n    font-size: 14px;\n    color: #333;\n  } */\n/* rating style */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
