// client/src/components/MetricCard.js
import React from 'react';
import './MetricCard.css';

const MetricCard = ({ title, value }) => {
  return (
    <div className="metric-card">
      <h4>{title}</h4>
      <p>{value}</p>
    </div>
  );
};

export default MetricCard;
