// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* client/src/layout/BaseLayout/BaseLayout.css */

.base-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Высота занимает все окно браузера */
  }
  
  .content-wrapper {
    flex: 1 1; /* Основной контент занимает оставшееся пространство */
    display: flex;
    flex-direction: column;
  }
  `, "",{"version":3,"sources":["webpack://./src/layout/BaseLayout/BaseLayout.css"],"names":[],"mappings":"AAAA,gDAAgD;;AAEhD;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB,EAAE,sCAAsC;EAC3D;;EAEA;IACE,SAAO,EAAE,sDAAsD;IAC/D,aAAa;IACb,sBAAsB;EACxB","sourcesContent":["/* client/src/layout/BaseLayout/BaseLayout.css */\n\n.base-layout {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh; /* Высота занимает все окно браузера */\n  }\n  \n  .content-wrapper {\n    flex: 1; /* Основной контент занимает оставшееся пространство */\n    display: flex;\n    flex-direction: column;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
