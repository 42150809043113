// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-courses-item {
  background: linear-gradient(0deg, #f3f2f3, #f3f2f3),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  border-radius: 16px;
  padding: 12px;
  display: flex;
  align-items: center;
  width: 46.7%;
  cursor: pointer;
}

.recent-courses-item-img {
  width: 24px;
  height: 24px;
  margin-right: 7px;
}

.recent-courses-item-img img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.recent-courses-item-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
}

@media screen and (max-width: 425px) {
  .recent-courses-item {
    width: 100%;
  }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
  .recent-courses-item {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .recent-courses-item {
    width: 44.7%;
  }

  .recent-courses-items-container {
    justify-content: space-between;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/RecentCoursesCard/RecentCoursesCard.css"],"names":[],"mappings":"AAAA;EACE;;;;;KAKG;EACH,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,YAAY;EACd;;EAEA;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".recent-courses-item {\n  background: linear-gradient(0deg, #f3f2f3, #f3f2f3),\n    radial-gradient(\n      50% 50% at 50% 50%,\n      rgba(255, 255, 255, 0.8) 0%,\n      rgba(255, 255, 255, 0) 100%\n    );\n  border-radius: 16px;\n  padding: 12px;\n  display: flex;\n  align-items: center;\n  width: 46.7%;\n  cursor: pointer;\n}\n\n.recent-courses-item-img {\n  width: 24px;\n  height: 24px;\n  margin-right: 7px;\n}\n\n.recent-courses-item-img img {\n  width: 100%;\n  height: 100%;\n  border-radius: 4px;\n}\n\n.recent-courses-item-title {\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 14px;\n  text-align: left;\n}\n\n@media screen and (max-width: 425px) {\n  .recent-courses-item {\n    width: 100%;\n  }\n}\n\n@media screen and (min-width: 426px) and (max-width: 767px) {\n  .recent-courses-item {\n    width: 100%;\n  }\n}\n@media screen and (min-width: 768px) and (max-width: 1023px) {\n  .recent-courses-item {\n    width: 44.7%;\n  }\n\n  .recent-courses-items-container {\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
