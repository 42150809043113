// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recommendation-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.1em;
  margin-top: 43px;
  margin-bottom: 12px;
  margin-left: 12px;
}

.recommendation-container {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  height: auto;
}

.dots-container {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/UI/Recommendations/Recommendations.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,QAAQ;EACR,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".recommendation-title {\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 12px;\n  letter-spacing: 0.1em;\n  margin-top: 43px;\n  margin-bottom: 12px;\n  margin-left: 12px;\n}\n\n.recommendation-container {\n  display: flex;\n  overflow-x: auto;\n  gap: 8px;\n  height: auto;\n}\n\n.dots-container {\n  display: flex;\n  justify-content: center;\n  margin-top: 8px;\n}\n\n.dot {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: #ccc;\n  margin: 0 5px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.dot.active {\n  background-color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
