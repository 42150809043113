// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.curses-banners {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  margin-top: 49px;
  margin-bottom: 49px;
}
`, "",{"version":3,"sources":["webpack://./src/UI/CoursesBanners/CoursesBanners.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".curses-banners {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 13px;\n  margin-top: 49px;\n  margin-bottom: 49px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
