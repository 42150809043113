// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support {
  position: fixed;
  left: 20px;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #2aabee 0%, #229ed9 99.93%);
  padding: 22px 30px;
  gap: 18px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  z-index: 2;
}

.support:hover {
  opacity: 0.8;
}

.support-icon {
  width: 20px;
  height: 17px;
}

.support-icon img {
  width: 100%;
  height: 100%;
}

.support-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/UI/ButtonSupport/ButtonSupport.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,8DAA8D;EAC9D,kBAAkB;EAClB,SAAS;EACT,mBAAmB;EACnB,eAAe;EACf,4BAA4B;EAC5B,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,kCAAkC;EAClC,8BAA8B;EAC9B,YAAY;AACd","sourcesContent":[".support {\n  position: fixed;\n  left: 20px;\n  bottom: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: linear-gradient(90deg, #2aabee 0%, #229ed9 99.93%);\n  padding: 22px 30px;\n  gap: 18px;\n  border-radius: 16px;\n  cursor: pointer;\n  transition: all 0.3s ease-in;\n  z-index: 2;\n}\n\n.support:hover {\n  opacity: 0.8;\n}\n\n.support-icon {\n  width: 20px;\n  height: 17px;\n}\n\n.support-icon img {\n  width: 100%;\n  height: 100%;\n}\n\n.support-title {\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: center;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
