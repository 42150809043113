// client/src/pages/HomePage/HomePage.js
import React, { useState, useEffect, useContext } from "react";
import "./HomePage.css";
import {
  Banner,
  Courses,
  CoursesBanners,
  RecentCourses,
  Recommendations,
} from "../../UI";
import { getCategories, getCourses } from '../../api';
import { AuthContext } from '../../context/AuthContext';
import { sampleSize } from 'lodash'; // Используем sampleSize из lodash
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const isAuth = !!user;
  const [categories, setCategories] = useState([]);
  const [recommendation, setRecommendation] = useState([]);
  const [recentCourses, setRecentCourses] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCategories();
    fetchRecentCourses(); // Получаем реальные курсы
  }, []);

  useEffect(() => {
    if (!isAuth) {
      navigate("/guest")
    }
  }, [isAuth])

  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      setCategories(data);
    } catch (error) {
      setError(error.error || 'Ошибка при получении категорий.');
      console.error('Ошибка при получении категорий:', error);
    }
  };

  const fetchRecentCourses = async () => {
    try {
      const data = await getCourses();
    
      setRecentCourses(data.slice(0, 10000)); // Берём первые 10,000 курсов

      if (data.length >= 4) {
        // Выбираем 4 случайных курса
        const selectedRecommendations = sampleSize(data, 4).map(course => ({
          img: course.imageUrl || course.image, // Замените на правильное поле
          title: course.title,
          _id: course._id, // Убедитесь, что у курсов есть поле _id
        }));
        setRecommendation(selectedRecommendations);

      } else {
        // Если курсов меньше 4, используем все
        const selectedRecommendations = data.map(course => ({
          img: course.imageUrl || course.image,
          title: course.title,
          _id: course._id,
        }));
        setRecommendation(selectedRecommendations);

      }
    } catch (error) {
      setError(error.error || 'Ошибка при получении курсов.');
      console.error('Ошибка при получении курсов:', error);
    }
  };

  return (
    <div className="home-page">
      {!isAuth && <Banner />}
      <div
        className="home-page-content"
        style={{ marginTop: isAuth ? "28px" : "0" }}
      >
        {error && <p className="error-message">{error}</p>}
        <Courses categories={categories} />

        {/* Передаем динамические рекомендации */}
        <Recommendations recommendation={recommendation} error={error} />

        <CoursesBanners recommendation={recommendation} />

        <RecentCourses recentCourses={recentCourses} />
      </div>
    </div>
  );
};

export default HomePage;
