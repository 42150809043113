// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-view-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.course-view-page .curses-banners {
    margin-top: 28px;
}

.course-view-page-body {
    margin: 0 72px;
}

.course-view-page-title {
    margin-top: 32px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .course-view-page .curses-banners {
        margin: 20px 39px;
    }

    .course-view-page-body {
        width: 100%;
    }

    .course-view-page-title {
        margin-left: 48px;
    }

    .course-view-page .curses-banners-item {

    }
}

@media screen and (min-width: 1920px) {
    .course-view-page-body {
        margin: 0 258px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/CourseViewPage/CourseViewPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,iBAAiB;IACrB;;IAEA;;IAEA;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".course-view-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.course-view-page .curses-banners {\n    margin-top: 28px;\n}\n\n.course-view-page-body {\n    margin: 0 72px;\n}\n\n.course-view-page-title {\n    margin-top: 32px;\n}\n\n@media screen and (min-width: 768px) and (max-width: 1023px) {\n    .course-view-page .curses-banners {\n        margin: 20px 39px;\n    }\n\n    .course-view-page-body {\n        width: 100%;\n    }\n\n    .course-view-page-title {\n        margin-left: 48px;\n    }\n\n    .course-view-page .curses-banners-item {\n\n    }\n}\n\n@media screen and (min-width: 1920px) {\n    .course-view-page-body {\n        margin: 0 258px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
