// client/src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getCurrentUser } from '../api';
import PropTypes from 'prop-types';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Оставим функцию registerGuest, если захотите вызывать её из других компонентов
  // Но не вызываем её здесь автоматически
  const registerGuest = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/users/register-guest', {
        method: 'POST',
      });
      const data = await response.json();

      if (data.success && data.token) {
        login(data.token);
        return data.user;
      } else {
        console.error('Не удалось зарегистрировать гостя:', data.message);
        return null;
      }
    } catch (error) {
      console.error('Ошибка при регистрации гостя:', error);
      return null;
    }
  };

  const loadUser = async (token) => {
    if (token) {
      try {
        const data = await getCurrentUser(token);
        setUser(data.user ? data.user : data);
      } catch (error) {
        console.error('Ошибка при загрузке пользователя:', error);
        setAuthToken(null);
        localStorage.removeItem('token');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const initAuth = async () => {
      if (authToken) {
        // Если токен есть, загружаем пользователя
        await loadUser(authToken);
      } else {
        // Нет токена - просто завершаем загрузку,
        // пользователь не авторизован и не гость
        setLoading(false);
      }
    };

    initAuth();
  }, [authToken]);

  const login = (token) => {
    localStorage.setItem('token', token);
    setAuthToken(token);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ authToken, user, setUser, login, logout, loading, registerGuest }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
