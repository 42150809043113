// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/svg/ai-reminder.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generate-by-ai-container {
  padding: 16px 0;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: start;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: contain;
  /* border: 2px solid #ffffff33; */
  border-radius: 12px;
  width: 348px;
}

.generate-by-ai-container-titles-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.generate-by-ai-container-title {
  font-family: "Geologica";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

.generate-by-ai-container-subtitle {
  font-family: "Geologica";
  font-size: 10px;
  font-weight: 200;
  line-height: 12px;
  letter-spacing: 0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

@media screen and (max-width: 320px) {
  .generate-by-ai-container {
    width: calc(280px - 32px);
    background-size: cover;
    padding: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/UI/GenerateByAI/GenerateByAI.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,QAAQ;EACR,kBAAkB;EAClB,oEAAoE;EACpE,wBAAwB;EACxB,iCAAiC;EACjC,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,kCAAkC;EAClC,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,kCAAkC;EAClC,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE;IACE,yBAAyB;IACzB,sBAAsB;IACtB,aAAa;EACf;AACF","sourcesContent":[".generate-by-ai-container {\n  padding: 16px 0;\n  display: flex;\n  justify-content: center;\n  gap: 4px;\n  align-items: start;\n  background: url(\"../../assets/svg/ai-reminder.svg\") no-repeat center;\n  background-size: contain;\n  /* border: 2px solid #ffffff33; */\n  border-radius: 12px;\n  width: 348px;\n}\n\n.generate-by-ai-container-titles-container {\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n}\n\n.generate-by-ai-container-title {\n  font-family: \"Geologica\";\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 16px;\n  letter-spacing: -0.01em;\n  text-align: left;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  color: white;\n}\n\n.generate-by-ai-container-subtitle {\n  font-family: \"Geologica\";\n  font-size: 10px;\n  font-weight: 200;\n  line-height: 12px;\n  letter-spacing: 0.01em;\n  text-align: left;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  color: white;\n}\n\n@media screen and (max-width: 320px) {\n  .generate-by-ai-container {\n    width: calc(280px - 32px);\n    background-size: cover;\n    padding: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
