import React from "react";
import "./GuestFooter.css";
import Logo from "../../assets/svg/logo.svg";

export const GuestFooter = () => {
  return (
    <div className="guest-info-comp">
      <div className="footer-btn-container">
        <a href="/public_offer.pdf" download className="footer-btn">
          Публичная оферта
        </a>
        <a href="/privacy_policy.pdf" download className="footer-btn">
          Политика конфиденциальности
        </a>
      </div>
      <div className="inc">
        © 2024,
        <div className="bottom-logo">
          <img src={Logo} alt="logo" />
        </div>
      </div>
      <div>8 800 100 10 01, info@neyrotalim.com</div>
    </div>
  );
};

export default GuestFooter;
