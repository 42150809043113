// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  position: relative;
}

.custom-radio input[type="radio"] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.radio-custom {
  width: 20px;
  height: 20px;
  border: 2px solid #999;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s ease;
}

.radio-custom::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.custom-radio input[type="radio"]:checked + .radio-custom::after {
  opacity: 1;
}

.custom-radio input[type="radio"]:checked + .radio-custom {
  border-color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomRadio/CustomRadio.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".custom-radio {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 16px;\n  text-align: left;\n  position: relative;\n}\n\n.custom-radio input[type=\"radio\"] {\n  opacity: 0;\n  position: absolute;\n  cursor: pointer;\n}\n\n.radio-custom {\n  width: 20px;\n  height: 20px;\n  border: 2px solid #999;\n  border-radius: 50%;\n  margin-right: 10px;\n  display: inline-block;\n  position: relative;\n  transition: background-color 0.3s ease;\n}\n\n.radio-custom::after {\n  content: \"\";\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: #333;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  opacity: 0;\n  transition: opacity 0.2s ease;\n}\n\n.custom-radio input[type=\"radio\"]:checked + .radio-custom::after {\n  opacity: 1;\n}\n\n.custom-radio input[type=\"radio\"]:checked + .radio-custom {\n  border-color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
