import React from "react";
import "./StarRating.css";

export const StarRating = ({ rating, votes, size }) => {
  const renderStar = (index) => {
    const fill =
      rating >= index + 1
        ? "black" // Полностью закрашенная звезда
        : rating > index
        ? "url(#half-fill)" // Наполовину закрашенная звезда
        : "white"; // Пустая звезда

    return (
      <svg
        key={index}
        width={size ? size : "28"}
        height={size ? size : "28"}
        className={`star ${fill === "white" ? "empty-star" : ""} ${
          !votes ? "empty-star stroke" : ""
        }`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Градиент для половинной звезды */}
        {fill === "url(#half-fill)" && (
          <defs>
            <linearGradient id="half-fill" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="50%" stopColor="black" />
              <stop offset="50%" stopColor="white" />
            </linearGradient>
          </defs>
        )}
        <path
          d="M10.8791 1.27126C11.3376 0.342199 12.6624 0.342198 13.1209 1.27126L15.8237 6.7476C16.0057 7.11653 16.3577 7.37224 16.7648 7.4314L22.8083 8.30958C23.8336 8.45856 24.243 9.71852 23.5011 10.4417L19.128 14.7044C18.8334 14.9916 18.6989 15.4054 18.7685 15.8108L19.8008 21.8299C19.976 22.8511 18.9042 23.6298 17.9871 23.1477L12.5817 20.3058C12.2175 20.1144 11.7825 20.1144 11.4183 20.3058L6.01284 23.1477C5.09581 23.6298 4.02402 22.8511 4.19916 21.8299L5.23151 15.8108C5.30106 15.4054 5.16662 14.9916 4.87201 14.7044L0.498892 10.4417C-0.243004 9.71852 0.166383 8.45856 1.19166 8.30958L7.23516 7.4314C7.6423 7.37224 7.99426 7.11653 8.17634 6.7476L10.8791 1.27126Z"
          fill={fill}
        />
      </svg>
    );
  };

  const declension = (number, words) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return words[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  };
  const word = declension(votes, ["голос", "голоса", "голосов"]);

  return (
    <div className="star-rating">
      <div className="stars">
        {Array.from({ length: 5 }, (_, index) => renderStar(index))}
      </div>
      {votes && (
        <p>
          {votes} {word}
        </p>
      )}
    </div>
  );
};
