import React from "react";
import './CardPrice.css'
import { CardWithImage } from "../../components";
import Img3 from "../../assets/svg/image-3.svg";
import Uzard from "../../assets/svg/uzcard-logo.svg";
import Humo from "../../assets/svg/logo-humo.svg";
import Cert from '../../assets/svg/certificate-icon.svg'

export const CardPrice = () => {
  return (
    <CardWithImage img={Img3} title={"Доступные цены"} imgPosition="left">
      <div className="price-container">
        <div className="price-info">
          <div className="price-container-title">Стоимость курсов</div>
          <div className="price-container-price">18 000 – 24 000 сум</div>
          <div className="price-container-payments">
          Оплата <img src={Uzard} alt="" /> | <img src={Humo} alt="" />
          </div>
        </div>
        <div className="cert-container">
          <img src={Cert} alt="" />
          <div className="cert-title">Возможность получения сертификата</div>
        </div>
      </div>
      <div></div>
    </CardWithImage>
  );
};
