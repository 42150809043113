import React from "react";
import { CardWithImage } from "../../components";
import Img2 from "../../assets/svg/image-2.svg";

export const CardResults = () => {
  return (
    <CardWithImage
      img={Img2}
      title={"Достигайте цели за минимальное время"}
      imgPosition="right"
    >
      <ul className="list-revolution">
        <li>Продолжительность каждого курса до 1 часа</li>
        <li>Удобный формат: текст + аудио</li>
        <li>Можно учиться в любое время и в любом месте</li>
      </ul>
    </CardWithImage>
  );
};
