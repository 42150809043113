import React from "react";
import "./Banner.css";
import Image from "../../assets/img/image-header.png";
import { useNavigate } from "react-router-dom";

export const Banner = () => {
  const navigate = useNavigate();
  const handleGoToSignIn = () => navigate("/sign-in");
  return (
    <div className="banner">
      <div className="banner-left-side">
        <div className="banner-title">
        Ro'yxatdan o'ting va birinchi kursingizni BEPUL oling!
          <br />
          Istalgan mavzudan birta onlayn kursni tanlang va bugun boshlang!
        </div>
        <div className="banner-button" onClick={handleGoToSignIn}>
        Bepul kursni olish
        </div>
      </div>
      <div className="banner-right-side">
        <div className="banner-image-container">
          <img src={Image} alt="" />
        </div>
      </div>
    </div>
  );
};
