// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.curses-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 16px;
}

.curses-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

@media screen and (max-width: 425px) {
  .curses-title {
    font-size: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/UI/Courses/Courses.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,QAAQ;AACV;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".curses-title {\n  font-size: 40px;\n  font-weight: 700;\n  line-height: 40px;\n  letter-spacing: -0.02em;\n  text-align: left;\n  margin-bottom: 16px;\n}\n\n.curses-categories {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px;\n}\n\n@media screen and (max-width: 425px) {\n  .curses-title {\n    font-size: 25px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
