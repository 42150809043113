// client/src/pages/CurrentCourseCard/CurrentCourseCard.jsx
import React, { useContext, useState, useEffect } from "react";
import "./CurrentCourseCard.css";
import Modal from "react-modal";
import LikeIcon from "../../assets/svg/like.svg";
import UnlikeIcon from "../../assets/svg/unlike.svg";
import Download from "../../assets/svg/download.svg";
import { AuthContext } from "../../context/AuthContext";
import { likeCourse, unlikeCourse } from "../../api";
import Img from "../../assets/img/img-course-1.jpeg";
import Img2 from "../../assets/img/img-course-2.jpeg";
import Img3 from "../../assets/img/img-course-3.jpeg";
import { GenerateByAI, StarRating, SubscriptionCard } from "../../UI";
import { ReviewsContainer } from "../../UI/ReviewsContainer/ReviewsContainer";

const images = [Img, Img2, Img3];



export const CurrentCourseCard = ({ item }) => {
  const { authToken, user, setUser, login, registerGuest } =
    useContext(AuthContext);
  const [isLiked, setIsLiked] = useState(false);
  const [isBought, setIsBought] = useState(false);
  const [isLikeModalOpen, setIsLikeModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (user && user.likes) {
      setIsLiked(user.likes.includes(item._id));
    }
    if (user && user.courses) {
      setIsBought(user.courses.includes(item._id));
    }
  }, [user, item._id]);

  const handlePaymentRedirect = () => {
    const merchantId = "67232a7de51de1c6a3a73f8f";
    const price =
      typeof item.price === "number" ? item.price : parseFloat(item.price);
    const amountInTiyins = price * 100;
    const callbackUrl = `https://neyrotalim.com/profile?status=success&courseId=${item._id}`;
    const encodedUrl = btoa(
      `m=${merchantId};ac.order_id=${item._id};a=${amountInTiyins};l=ru;c=${callbackUrl}`
    );
    const paymentUrl = `https://checkout.paycom.uz/${encodedUrl}`;
    window.location.href = paymentUrl;
  };

  const handlePaymentInitiation = async () => {
    if (!authToken) {
      const guestUser = await registerGuest();
      if (!guestUser) {
        alert("Не удалось создать гостевого пользователя. Повторите попытку позже.");
        return;
      }
    }
    handlePaymentRedirect();
  };

  const handleLike = async () => {
    if (!authToken) {
      setIsLikeModalOpen(true);
      return;
    }

    try {
      if (isLiked) {
        const data = await unlikeCourse(authToken, item._id);
        setUser((prevUser) => ({
          ...prevUser,
          likes: data.likes,
        }));
        setIsLiked(false);
      } else {
        const data = await likeCourse(authToken, item._id);
        setUser((prevUser) => ({
          ...prevUser,
          likes: data.likes,
        }));
        setIsLiked(true);
      }
    } catch (error) {
      alert(error.error || "Произошла ошибка при обработке лайка.");
    }
  };

  const handleDownloadPDF = () => {
    if (item.pdf) {
      window.open(item.pdf, "_blank");
    } else {
      alert("PDF файл недоступен.");
    }
  };

  const handleDownloadAudio = () => {
    if (item.audio) {
      window.open(item.audio, "_blank");
    } else {
      alert("Aудиофайл недоступен.");
    }
  };

  const closeLikeModal = () => {
    setIsLikeModalOpen(false);
  };

  const adminReviews = (item.comments || []).map((c) => ({
    name: c.name,
    age: c.age,
    rating: c.rating,
    text: c.text,
  }));

  const courseReviews = [...adminReviews];

  return (
    <div
      className="current-course-card"
      style={{ gap: !isBought ? "6px" : "46px" }}
    >
      <div className="current-course-card-container">
        <div className="current-course-card-images-container">
          <div className="current-course-card-form-image">
            <div className="current-course-card-img">
              <img className="img" src={item.image} alt={item.title} />
            </div>
            <div className="current-course-card-img-title">{item.title}</div>
          </div>

          <div className="current-course-card-thumbnail">
            {images.map((imgItem, index) => {
              return (
                <div
                  key={`images-thumbnail-${index}`}
                  className="current-course-card-thumbnail-img-container"
                >
                  <img src={imgItem} alt="" />
                </div>
              );
            })}
          </div>
        </div>

        <div className="current-course-card-body">
          <div className="current-course-card-title">{item.title}</div>
          <div className="current-course-card-category">
            {item.categories && item.categories.length > 0
              ? item.categories.map((cat) => (
                  <div key={cat._id} className="current-course-card-category-item">
                    {cat.name}
                  </div>
                ))
              : "Категория отсутствует"}
          </div>
          <div className="rating-ai-container">
            <StarRating votes={124} rating={4} />
            <GenerateByAI />
          </div>
          <div className="current-course-card-description">
            {item.description && <p>{item.description}</p>}
          </div>
          {isMobile && (
            <div className="course-info">
              <div className="course-info-item">
                <div className="course-info-item-title">Объем</div>
                <div className="course-info-item-subtitle">22 страницы</div>
              </div>
              <div className="course-info-item">
                <div className="course-info-item-title">Дата создания</div>
                <div className="course-info-item-subtitle">14.09.2024</div>
              </div>
              <div className="course-info-item">
                <div className="course-info-item-title">Формат</div>
                <div className="course-info-item-subtitle">PDF</div>
              </div>
            </div>
          )}
          <div style={{ width: "max-content" }}>
            <div className="current-course-card-btns-container">
              <div
                style={{
                  paddingLeft: !isBought && !isMobile ? "41px" : "30px",
                  paddingRight: !isBought && !isMobile ? "61px" : "46px",
                }}
                className="btn-buy"
                onClick={!isBought ? handlePaymentInitiation : handleDownloadPDF}
              >
                {isBought && <img src={Download} alt="Download PDF" />}
                {isBought ? (
                  "Скачать PDF"
                ) : (
                  <div className="btn-buy-price-container">
                    <div className="current-price">
                      Купить за{" "}
                      <div className="current-price relative">
                        {item.price}
                        <div className="old-price">24000UZS</div>
                      </div>{" "}
                      UZS
                    </div>
                    <div className="discount-badge">-50%</div>
                  </div>
                )}
              </div>
              {isBought && item.audio && (
                <div
                  className="btn-download-audio"
                  onClick={handleDownloadAudio}
                >
                  <img src={Download} alt="Download Audio" />
                  Скачать аудиофайл
                </div>
              )}
              <div className="btn-like" onClick={handleLike}>
                <img src={isLiked ? UnlikeIcon : LikeIcon} alt="like" />
              </div>
            </div>
            {!isBought && <SubscriptionCard />}
          </div>
        </div>
      </div>

      <ReviewsContainer reviews={courseReviews} />

      <Modal
        isOpen={isLikeModalOpen}
        onRequestClose={closeLikeModal}
        contentLabel="Требуется вход"
        className="modal-content modal-content-custom"
        overlayClassName="modal-overlay modal-overlay-custom"
        ariaHideApp={false}
      >
        <div className="modal-header">
          <div className="modal-header-text">Войдите, чтобы лайкнуть курс.</div>
        </div>
        <div className="modal-message">
          <button
            onClick={closeLikeModal}
            className="modal-close-button modal-close-button-custom"
          >
            Закрыть
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CurrentCourseCard;
