import React from "react";
import './GenerateByAI.css'
import stars from "../../assets/svg/stars.svg";

export const GenerateByAI = () => {
  return (
    <div className="generate-by-ai-container">
      <div className="generate-by-ai-icon-container">
        <img src={stars} alt="" />
      </div>
      <div className="generate-by-ai-container-titles-container">
        <div className="generate-by-ai-container-title">
          Курс создан с помощью нейросети
        </div>
        <div className="generate-by-ai-container-subtitle">
          актуальные материалы, персонализация и удобство 24/7
        </div>
      </div>
    </div>
  );
};
