import React from "react";
import "./CardWithImage.css";

export const CardWithImage = ({ title, children, img, imgPosition }) => {
  return (
    <div className="card-img-left">
      <div className="card-img-left-title">{title}</div>
      <div
        className={`card-img-left-content ${
          imgPosition === "right" ? "reverse" : ""
        }`}
      >
        {imgPosition === "left" && (
          <div className="card-img-left-side">
            <img src={img} alt="card-info" />
          </div>
        )}
        <div className="card-img-right-side">{children}</div>
        {imgPosition === "right" && (
          <div className="card-img-left-side">
            <img src={img} alt="card-info" />
          </div>
        )}
      </div>
    </div>
  );
};
