// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* client/src/components/MetricCard.css */

.metric-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
    margin: 10px;
    flex: 1 1;
    text-align: center;
  }
  
  .metric-card h4 {
    margin-bottom: 10px;
    color: #555;
  }
  
  .metric-card p {
    font-size: 24px;
    color: #000;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MetricCard.css"],"names":[],"mappings":"AAAA,yCAAyC;;AAEzC;IACI,sBAAsB;IACtB,kBAAkB;IAClB,qCAAqC;IACrC,aAAa;IACb,YAAY;IACZ,SAAO;IACP,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,WAAW;EACb","sourcesContent":["/* client/src/components/MetricCard.css */\r\n\r\n.metric-card {\r\n    background-color: #fff;\r\n    border-radius: 8px;\r\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1);\r\n    padding: 20px;\r\n    margin: 10px;\r\n    flex: 1;\r\n    text-align: center;\r\n  }\r\n  \r\n  .metric-card h4 {\r\n    margin-bottom: 10px;\r\n    color: #555;\r\n  }\r\n  \r\n  .metric-card p {\r\n    font-size: 24px;\r\n    color: #000;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
