import React from "react";
import './Footer.css';
import Logo from '../../assets/svg/logo.svg'; // Импортируем логотип

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-btn-container">
        <a href="/public_offer.pdf" download className="footer-btn">Публичная оферта</a>
        <a href="/privacy_policy.pdf" download className="footer-btn">Политика конфиденциальности</a>
      </div>
      <div className="footer-logo-container">
        {/* Добавляем дополнительную информацию с датой и контактом */}
        <div className="footer-info">
          <p>&copy; 2024, <img className="footer-logo" src={Logo} alt="logo" /></p>
        </div>
        <div className="informationq">
          <p>8 800 100 10 01, info@neyrotalim.com</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
