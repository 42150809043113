import React from "react";
import "./RecommendationCard.css";

export const RecommendationCard = ({ img, title, onClick }) => {
  return (
    <div className="recommendation-item" onClick={onClick} style={{ cursor: "pointer" }}>
      <img 
        className="recommendation-item-img" 
        src={img} 
        alt={title} 
        style={{ objectFit: "cover", width: "100%", height: "350px", cursor: "pointer" }} // добавил cursor: pointer
      />
      <div className="recommendation-item-title">{title}</div>
      <div className="recommendation-item-button" onClick={onClick} style={{ cursor: "pointer" }}>
        Открыть →
      </div>
    </div>
  );
};
