// client/src/api.js
import axios from 'axios';

// Функция для получения токена из localStorage
const getToken = () => {
  return localStorage.getItem('token');
};

// Получение базового URL из переменных окружения или по умолчанию
const API_URL = process.env.REACT_APP_API_URL || 'https://neyrotalim.com/api';

// Создание экземпляра axios с базовым URL
const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

// Добавление интерсептора запроса для установки заголовка Authorization, если токен присутствует
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Обработчик ошибок
const handleError = (error) => {
  if (error.response) {
    console.error('API Error:', error.response.data);
    return Promise.reject(error.response.data);
  } else if (error.request) {
    console.error('No response received:', error.request);
    return Promise.reject({ error: 'Нет ответа от сервера' });
  } else {
    console.error('Error setting up request:', error.message);
    return Promise.reject({ error: 'Ошибка при настройке запроса' });
  }
};

// Добавление нового курса (без рекомендаций)
export const addCourse = async (courseData) => {
  try {
    // Проверяем, что данные корректны перед trim()
    const title = courseData.title ? courseData.title.toString().trim() : '';
    const description = courseData.description ? courseData.description.toString().trim() : '';
    const price = courseData.price !== undefined && courseData.price !== null ? courseData.price.toString().trim() : '';

    if (!title) {
      return Promise.reject({ error: 'Название курса обязательно' });
    }
    if (!description) {
      return Promise.reject({ error: 'Описание курса обязательно' });
    }
    if (!price) {
      return Promise.reject({ error: 'Цена курса обязательна' });
    }

    const priceNumber = parseFloat(price);
    if (isNaN(priceNumber) || priceNumber < 0) {
      return Promise.reject({ error: 'Цена должна быть положительным числом' });
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('price', priceNumber.toString());

    if (!courseData.categories || !Array.isArray(courseData.categories) || courseData.categories.length === 0) {
      return Promise.reject({ error: 'Выберите хотя бы одну категорию' });
    }
    formData.append('categories', JSON.stringify(courseData.categories));

    // Изображение (обязательно)
    if (!courseData.image) {
      return Promise.reject({ error: 'Изображение курса обязательно' });
    }
    formData.append('image', courseData.image);

    // PDF файл
    if (courseData.pdf) {
      formData.append('pdf', courseData.pdf);
    }

    // Аудиофайл
    if (courseData.audio) {
      formData.append('audio', courseData.audio);
    }

    // Комментарии (необязательно)
    if (courseData.comments && Array.isArray(courseData.comments) && courseData.comments.length > 0) {
      formData.append('comments', JSON.stringify(courseData.comments));
    } else {
      formData.append('comments', JSON.stringify([]));
    }

    const response = await api.post('/courses', formData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Обновление курса (без рекомендаций)
export const updateCourse = async (id, courseData) => {
  try {
    const title = courseData.title ? courseData.title.toString().trim() : '';
    if (!title) {
      return Promise.reject({ error: 'Название курса обязательно' });
    }

    const description = courseData.description ? courseData.description.toString().trim() : '';
    const price = courseData.price !== undefined && courseData.price !== null ? courseData.price.toString().trim() : '';

    if (!price) {
      return Promise.reject({ error: 'Цена курса обязательна' });
    }
    const priceNumber = parseFloat(price);
    if (isNaN(priceNumber) || priceNumber < 0) {
      return Promise.reject({ error: 'Цена должна быть положительным числом' });
    }

    if (!courseData.categories || !Array.isArray(courseData.categories) || courseData.categories.length === 0) {
      return Promise.reject({ error: 'Выберите хотя бы одну категорию' });
    }

    const formData = new FormData();
    formData.append('title', title);
    if (description) {
      formData.append('description', description);
    }
    formData.append('price', priceNumber.toString());
    formData.append('categories', JSON.stringify(courseData.categories));

    if (courseData.image) {
      formData.append('image', courseData.image);
    }
    if (courseData.pdf) {
      formData.append('pdf', courseData.pdf);
    }
    if (courseData.audio) {
      formData.append('audio', courseData.audio);
    }

    if (courseData.comments && Array.isArray(courseData.comments) && courseData.comments.length > 0) {
      formData.append('comments', JSON.stringify(courseData.comments));
    } else {
      formData.append('comments', JSON.stringify([]));
    }

    const response = await api.put(`/courses/${id}`, formData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Ниже остальные функции без изменений

export const getCoursesByCategory = async (categoryId) => {
  try {
    const response = await api.get(`/courses/category/${categoryId}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getCourses = async () => {
  try {
    const response = await api.get('/courses');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getCourseById = async (id) => {
  try {
    const response = await api.get(`/courses/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteCourse = async (id) => {
  try {
    const response = await api.delete(`/courses/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getCategories = async () => {
  try {
    const response = await api.get('/categories');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getCategoryById = async (id) => {
  try {
    const response = await api.get(`/categories/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const addCategory = async (name) => {
  try {
    const response = await api.post('/categories', { name });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateCategory = async (id, name) => {
  try {
    const response = await api.put(`/categories/${id}`, { name });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await api.delete(`/categories/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const registerUser = async (phone, password, selectedCourse) => {
  try {
    const response = await api.post('/auth/register', {
      phone,
      password,
      selectedCourse,
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const loginUser = async (phone, password) => {
  try {
    const response = await api.post('/auth/login', { phone, password });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getCurrentUser = async (token) => {
  try {
    const response = await api.get('/auth/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getUsers = async () => {
  try {
    const response = await api.get('/users');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getUserProfile = async (token) => {
  try {
    const response = await api.get('/auth/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const likeCourse = async (token, courseId) => {
  try {
    const response = await api.post(
      `/auth/like/${courseId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const unlikeCourse = async (token, courseId) => {
  try {
    const response = await api.delete(`/auth/like/${courseId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const addUser = async (userData) => {
  try {
    const response = await api.post('/users', userData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateUser = async (id, userData) => {
  try {
    const response = await api.put(`/users/${id}`, userData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await api.delete(`/users/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateUserSettings = async (token, settings) => {
  try {
    const response = await api.put('/auth/settings', settings, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Аналитика API

export const getTotalVisitors = async (startDate, endDate) => {
  try {
    const response = await api.get('/analytics/total-visitors', {
      params: { start: startDate, end: endDate },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getTrafficSources = async (startDate, endDate) => {
  try {
    const response = await api.get('/analytics/traffic-sources', {
      params: { start: startDate, end: endDate },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getAverageDepth = async (startDate, endDate) => {
  try {
    const response = await api.get('/analytics/average-depth', {
      params: { start: startDate, end: endDate },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getAverageSessionDuration = async (startDate, endDate) => {
  try {
    const response = await api.get('/analytics/average-session-duration', {
      params: { start: startDate, end: endDate },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getPopularPages = async (startDate, endDate) => {
  try {
    const response = await api.get('/analytics/popular-pages', {
      params: { start: startDate, end: endDate },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getCompletionRate = async (startDate, endDate) => {
  try {
    const response = await api.get('/analytics/completion-rate', {
      params: { start: startDate, end: endDate },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getSalesCount = async (startDate, endDate) => {
  try {
    const response = await api.get('/analytics/sales-count', {
      params: { start: startDate, end: endDate },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getRepeatPurchases = async (startDate, endDate) => {
  try {
    const response = await api.get('/analytics/repeat-purchases', {
      params: { start: startDate, end: endDate },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Функция для инициации платежа
export const initiatePayment = async (courseId, userId) => {
  try {
    const response = await axios.post('https://neyrotalim.com/api/payment/initiate', {
      courseId,
      userId,
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при инициализации платежа:', error);
    throw error;
  }
};

// Поиск курсов
export const searchCourses = async (query) => {
  try {
    const response = await api.get(`/courses/search?query=${encodeURIComponent(query)}`);
    return response.data;
  } catch (error) {
    console.error('Ошибка при поиске курсов:', error);
    throw error;
  }
};

export default api;
