// client/src/pages/AdminPage/AnalyticsPage.js
import React, { useState, useEffect } from 'react';
import {
  getTotalVisitors,
  getTrafficSources,
  getAverageDepth,
  getAverageSessionDuration,
  getPopularPages,
  getCompletionRate,
  getSalesCount,
  getRepeatPurchases,
} from '../../api';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
} from 'recharts';
import MetricCard from '../../components/MetricCard';
import './AnalyticsPage.css';

const AnalyticsPage = () => {
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [trafficSources, setTrafficSources] = useState([]);
  const [averageDepth, setAverageDepth] = useState(0);
  const [averageSessionDuration, setAverageSessionDuration] = useState(0);
  const [popularPages, setPopularPages] = useState([]);
  const [completionRate, setCompletionRate] = useState({
    registrationCompletionRate: '0.00',
    paymentCompletionRate: '0.00',
  });
  const [salesCount, setSalesCount] = useState(0);
  const [repeatPurchases, setRepeatPurchases] = useState(0);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const fetchAnalytics = async () => {
    try {
      const [
        visitorsData,
        trafficData,
        depthData,
        sessionDurationData,
        pagesData,
        completionData,
        salesData,
        repeatData,
      ] = await Promise.all([
        getTotalVisitors(),
        getTrafficSources(),
        getAverageDepth(),
        getAverageSessionDuration(),
        getPopularPages(),
        getCompletionRate(),
        getSalesCount(),
        getRepeatPurchases(),
      ]);

      setTotalVisitors(visitorsData.totalVisitors);
      setTrafficSources(trafficData.trafficSources);
      setAverageDepth(depthData.averageDepth);
      setAverageSessionDuration(sessionDurationData.averageSessionDuration);
      setPopularPages(pagesData.popularPages);
      setCompletionRate(completionData);
      setSalesCount(salesData.salesCount);
      setRepeatPurchases(repeatData.repeatPurchases);
    } catch (error) {
      console.error('Ошибка при получении аналитических данных:', error);
    }
  };

  return (
    <div className="analytics-page">
      <h2>Аналитика</h2>

      <div className="metrics-container">
        <MetricCard title="Общее количество посетителей" value={totalVisitors} />
        <MetricCard title="Средняя глубина просмотра" value={`${averageDepth.toFixed(2)} страниц`} />
        <MetricCard title="Средняя продолжительность сеанса" value={`${averageSessionDuration.toFixed(2)} секунд`} />
        <MetricCard title="Количество продаж" value={salesCount} />
        <MetricCard title="Количество повторных покупок" value={repeatPurchases} />
      </div>

      <div className="chart-container">
        <h3>Источники трафика</h3>
        <PieChart width={400} height={300}>
          <Pie
            data={trafficSources}
            dataKey="count"
            nameKey="_id"
            cx="50%"
            cy="50%"
            outerRadius={100}
            label
          >
            {trafficSources.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>

      <div className="metric">
        <h3>Средняя глубина просмотра</h3>
        <p>{averageDepth.toFixed(2)} страниц за сеанс</p>
      </div>

      <div className="metric">
        <h3>Средняя продолжительность сеанса</h3>
        <p>{averageSessionDuration.toFixed(2)} секунд</p>
      </div>

      <div className="chart-container">
        <h3>Популярные страницы</h3>
        <BarChart width={500} height={300} data={popularPages}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="_id" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" fill="#8884d8" name="Просмотры" />
        </BarChart>
      </div>

      <div className="chart-container">
        <h3>Процент завершения регистрации и оплаты</h3>
        <BarChart width={500} height={300} data={[completionRate]}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="registrationCompletionRate" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="registrationCompletionRate" fill="#82ca9d" name="Ro’yxatdan o’tish" />
          <Bar dataKey="paymentCompletionRate" fill="#8884d8" name="Оплата" />
        </BarChart>
      </div>

      <div className="metric">
        <h3>Количество продаж</h3>
        <p>{salesCount}</p>
      </div>

      <div className="metric">
        <h3>Количество повторных покупок</h3>
        <p>{repeatPurchases}</p>
      </div>
    </div>
  );
};

export default AnalyticsPage;
