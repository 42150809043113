// client/src/pages/AdminPage/UsersAdminPage.js
import React, { useState, useEffect } from 'react';
import { getUsers, addUser, updateUser, deleteUser, getCourses } from '../../api';
import './UsersAdminPage.css';

const UsersAdminPage = () => {
  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [newUser, setNewUser] = useState({
    phone: '',
    password: '',
    courses: [],
  });
  const [editingUser, setEditingUser] = useState(null);
  const [editedUser, setEditedUser] = useState({
    phone: '',
    password: '',
    courses: [],
  });
  const [message, setMessage] = useState({ text: '', type: '' }); // { text: '', type: 'success' | 'error' }
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'phone', direction: 'ascending' });

  useEffect(() => {
    fetchUsers();
    fetchCourses();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await getUsers();
      setUsers(data);
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
      setMessage({ text: error.error || 'Ошибка при загрузке пользователей.', type: 'error' });
    }
  };

  const fetchCourses = async () => {
    try {
      const data = await getCourses();
      setCourses(data);
    } catch (error) {
      console.error('Ошибка при загрузке курсов:', error);
      setMessage({ text: error.error || 'Ошибка при загрузке курсов.', type: 'error' });
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    const { phone, password, courses } = newUser;
    if (!phone || !password) {
      setMessage({ text: 'Телефон и пароль обязательны для заполнения.', type: 'error' });
      return;
    }

    try {
      const data = await addUser({ phone, password, courses });
      setMessage({ text: data.message || 'Пользователь успешно добавлен.', type: 'success' });
      setNewUser({ phone: '', password: '', courses: [] });
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при добавлении пользователя:', error);
      setMessage({ text: error.error || 'Ошибка при добавлении пользователя.', type: 'error' });
    }
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setEditedUser({
      phone: user.phone,
      password: '',
      courses: user.courses ? user.courses.map((course) => course._id) : [],
    });
    setMessage({ text: '', type: '' }); // Сбросить сообщения при редактировании
  };

  const handleUpdateUser = async (id) => {
    const { password, courses } = editedUser;
    try {
      const data = await updateUser(id, { password, courses });
      setMessage({ text: data.message || 'Пользователь успешно обновлен.', type: 'success' });
      setEditingUser(null);
      setEditedUser({ phone: '', password: '', courses: [] });
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при обновлении пользователя:', error);
      setMessage({ text: error.error || 'Ошибка при обновлении пользователя.', type: 'error' });
    }
  };

  const openDeleteModal = (user) => {
    setUserToDelete(user);
    setIsDeleteModalOpen(true);
    setMessage({ text: '', type: '' }); // Сбросить сообщения при открытии модального окна
  };

  const closeDeleteModal = () => {
    setUserToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteUser = async () => {
    if (!userToDelete) return;
    try {
      const data = await deleteUser(userToDelete._id);
      setMessage({ text: data.message || 'Пользователь успешно удален.', type: 'success' });
      setUsers(users.filter((user) => user._id !== userToDelete._id));
      closeDeleteModal();
    } catch (error) {
      console.error('Ошибка при удалении пользователя:', error);
      setMessage({ text: error.error || 'Ошибка при удалении пользователя.', type: 'error' });
    }
  };

  const handleCourseToggle = (courseId, setFunction) => {
    setFunction((prevCourses) =>
      prevCourses.includes(courseId)
        ? prevCourses.filter((id) => id !== courseId)
        : [...prevCourses, courseId]
    );
  };

  const sortedUsers = React.useMemo(() => {
    let sortableUsers = [...users];

    if (searchTerm) {
      sortableUsers = sortableUsers.filter((user) =>
        user.phone.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortConfig !== null) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  }, [users, sortConfig, searchTerm]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="users-admin-page">
      <h2>Управление пользователями</h2>

      {/* Отображение сообщения об ошибке или успехе */}
      {message.text && (
        <div className={`message ${message.type}`}>
          {message.text}
        </div>
      )}

      {/* Форма добавления пользователя */}
      <section className="add-user-section">
        <h3>Добавить нового пользователя</h3>
        <form onSubmit={handleAddUser} className="add-user-form">
          <div className="form-group">
            <label htmlFor="phone">Телефон пользователя:</label>
            <input
              type="tel"
              id="phone"
              value={newUser.phone}
              onChange={(e) => setNewUser({ ...newUser, phone: e.target.value })}
              required
              placeholder="Введите телефон"
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Пароль пользователя:</label>
            <input
              type="password"
              id="password"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              required
              placeholder="Введите пароль"
            />
          </div>

          <div className="form-group">
            <label>Курсы пользователя:</label>
            <div className="courses-checkboxes">
              {courses.map((course) => (
                <label key={course._id} className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={newUser.courses.includes(course._id)}
                    onChange={() => handleCourseToggle(course._id, (selected) => setNewUser({ ...newUser, courses: selected }))}
                  />
                  {course.title}
                </label>
              ))}
            </div>
          </div>

          <button type="submit" className="add-user-button">
            Добавить пользователя
          </button>
        </form>
      </section>

      {/* Секция списка пользователей */}
      <section className="users-list-section">
        <h3>Список пользователей</h3>

        {/* Поиск и сортировка */}
        <div className="search-sort-container">
          <input
            type="text"
            placeholder="Поиск по телефону..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
            aria-label="Поиск пользователей по телефону"
          />
          <button
            onClick={() => requestSort('phone')}
            className="sort-button"
            aria-label="Сортировать по телефону"
          >
            Сортировать по телефону {sortConfig.key === 'phone' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
          </button>
        </div>

        {/* Таблица пользователей */}
        <table className="users-table">
          <thead>
            <tr>
              <th>Телефон</th>
              <th>Курсы</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {sortedUsers.length > 0 ? (
              sortedUsers.map((user) => (
                <tr key={user._id} className="user-row">
                  <td>{user.phone}</td>
                  <td>
                    {user.courses && user.courses.length > 0
                      ? user.courses.map((course) => course.title).join(', ')
                      : 'Нет курсов'}
                  </td>
                  <td>
                    <button onClick={() => handleEditUser(user)} className="edit-button">
                      Редактировать
                    </button>
                    <button onClick={() => openDeleteModal(user)} className="delete-button">
                      Удалить
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="no-users">
                  Пользователи не найдены.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>

      {/* Модальное окно для подтверждения удаления пользователя */}
      {isDeleteModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Подтвердите удаление</h2>
            {userToDelete && (
              <p>
                Вы уверены, что хотите удалить пользователя с телефоном <strong>{userToDelete.phone}</strong>?
              </p>
            )}
            <div className="modal-buttons">
              <button onClick={handleDeleteUser} className="confirm-button">
                Да, удалить
              </button>
              <button onClick={closeDeleteModal} className="cancel-button">
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Форма редактирования пользователя */}
      {editingUser && (
        <div className="edit-user-section">
          <h3>Редактировать пользователя</h3>
          <form className="edit-user-form">
            <div className="form-group">
              <label htmlFor="edit-phone">Телефон пользователя:</label>
              <input
                type="tel"
                id="edit-phone"
                value={editedUser.phone}
                onChange={(e) => setEditedUser({ ...editedUser, phone: e.target.value })}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="edit-password">Новый пароль:</label>
              <input
                type="password"
                id="edit-password"
                value={editedUser.password}
                onChange={(e) => setEditedUser({ ...editedUser, password: e.target.value })}
                placeholder="Введите новый пароль"
              />
            </div>

            <div className="form-group">
              <label>Курсы пользователя:</label>
              <div className="courses-checkboxes">
                {courses.map((course) => (
                  <label key={course._id} className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={editedUser.courses.includes(course._id)}
                      onChange={() => handleCourseToggle(course._id, (selected) => setEditedUser({ ...editedUser, courses: selected }))}
                    />
                    {course.title}
                  </label>
                ))}
              </div>
            </div>

            <div className="edit-buttons">
              <button type="button" onClick={() => handleUpdateUser(editingUser._id)} className="save-button">
                Сохранить
              </button>
              <button type="button" onClick={() => setEditingUser(null)} className="cancel-button">
                Отмена
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default UsersAdminPage;
