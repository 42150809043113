import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./SignInPage.css";
import { Footer, Header } from "../../components";
import { AuthContext } from "../../context/AuthContext";
import { registerUser, loginUser, getCourses } from "../../api";

const SignInPage = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [isRegister, setIsRegister] = useState(false);
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
    password2: "",
    selectedCourse: "",
  });
  const [message, setMessage] = useState("");
  const [courses, setCourses] = useState([]);
  const { phone, password, password2, selectedCourse } = formData;

  useEffect(() => {
    if (location.state?.autoRegister) {
      setIsRegister(true);
    }

    const fetchCourses = async () => {
      try {
        const data = await getCourses();
        const filteredCourses = data.filter((course) =>
          ["673de437a54fd4eddca354f2", "6718ff7412e902996cd8e7b1"].includes(
            course._id
          )
        );
        setCourses(filteredCourses);
      } catch (error) {
        console.error("Ошибка при загрузке курсов:", error);
      }
    };
    fetchCourses();
  }, [location.state]);

  const toggleForm = () => {
    setIsRegister(!isRegister);
    setFormData({ phone: "", password: "", password2: "", selectedCourse: "" });
    setMessage("");
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const selectCourse = (courseId) => {
    setFormData({ ...formData, selectedCourse: courseId });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isRegister) {
      if (password !== password2) {
        setMessage("Пароли не совпадают.");
        return;
      }
      if (!selectedCourse) {
        setMessage("Пожалуйста, выберите курс.");
        return;
      }
      try {
        const data = await registerUser(phone, password, selectedCourse);
        login(data.token);
        navigate("/profile");
      } catch (error) {
        setMessage(error.error || "Произошла ошибка при регистрации.");
      }
    } else {
      try {
        const data = await loginUser(phone, password);
        login(data.token);
        navigate("/profile");
      } catch (error) {
        setMessage(error.error || "Произошла ошибка при авторизации.");
      }
    }
  };

  return (
    <div className="sign-in-page">
      <Header />
      <div className="body-sign-in-container">
        <div className="body-sign-in">
          <form onSubmit={onSubmit} className="auth-form">
            <h2>{isRegister ? "Регистрация" : "Авторизация"}</h2>
            {message && <p className="message">{message}</p>}
            <label htmlFor="phone">Номер телефона:</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              value={phone}
              onChange={onChange}
              required
              placeholder="Введите ваш номер телефона"
            />
            <label htmlFor="password">Пароль:</label>
            <input
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={onChange}
              required
            />
            {isRegister && (
              <>
                <label htmlFor="password2">Подтверждение пароля:</label>
                <input
                  type="password"
                  name="password2"
                  id="password2"
                  value={password2}
                  onChange={onChange}
                  required
                />

                <label>Выберите бесплатный курс:</label>
                <div className="courses-selection">
                  {courses.map((course) => (
                    <div
                      key={course._id}
                      className={`course-card ${
                        selectedCourse === course._id ? "selected" : ""
                      }`}
                      onClick={() => selectCourse(course._id)}
                    >
                      <img
                        src={course.image}
                        alt={course.title}
                        className="course-image1"
                      />
                      <h3>{course.title}</h3>
                    </div>
                  ))}
                </div>
              </>
            )}

            <button type="submit">
              {isRegister ? "Регистрация" : "Войти"}
            </button>
          </form>
          <div className="toggle-form">
            {isRegister ? (
              <p>
                Есть аккаунт?{" "}
                <span className="toggle-link" onClick={toggleForm}>
                  Войти
                </span>
              </p>
            ) : (
              <p>
                Нет аккаунта?{" "}
                <span className="toggle-link" onClick={toggleForm}>
                  Регистрация
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignInPage;
