// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviews-container {
  display: flex;
  flex-direction: column;
}

.reviews-container-title {
  font-family: IBM Plex Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}

.reviews-toggle-button {
  border: none;
  background: black;
  border-radius: 10px;
  margin-top: 15px;
  color: white;
  padding: 15px 0;
  font-size: 16px;
  font-family: "Geologica";
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.reviews-toggle-button:hover {
    opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/UI/ReviewsContainer/ReviewsContainer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,kCAAkC;EAClC,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;EACf,4BAA4B;AAC9B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".reviews-container {\n  display: flex;\n  flex-direction: column;\n}\n\n.reviews-container-title {\n  font-family: IBM Plex Sans;\n  font-size: 32px;\n  font-weight: 600;\n  line-height: 44px;\n  letter-spacing: -0.02em;\n  text-align: left;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  color: black;\n}\n\n.reviews-toggle-button {\n  border: none;\n  background: black;\n  border-radius: 10px;\n  margin-top: 15px;\n  color: white;\n  padding: 15px 0;\n  font-size: 16px;\n  font-family: \"Geologica\";\n  font-weight: 400;\n  cursor: pointer;\n  transition: all 0.3s ease-in;\n}\n\n.reviews-toggle-button:hover {\n    opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
