import React from "react";
import { CardWithImage } from "../../components";
import Img1 from "../../assets/svg/image-1.svg";

export const CardRevolution = () => {
  return (
    <CardWithImage img={Img1} title={"Революция в обучении"} imgPosition="left">
      <ul className="list-revolution">
        <li>Курсы созданы с помощью современных инструментов искусственного интеллекта</li>
        <li>Актуальная и проверенная информация</li>
        <li>Быстрое освоение новых навыков</li>
      </ul>
    </CardWithImage>
  );
};
