// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* client/src/pages/AdminPage/AnalyticsPage.css */

.analytics-page {
    padding: 20px;
  }
  
  .metric {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .metric h3 {
    margin-bottom: 10px;
  }
  
  .chart-container {
    margin-bottom: 40px;
  }
  
  .message {
    color: red;
    margin: 10px 0;
  }
  
  @media (max-width: 768px) {
    .chart-container {
      overflow-x: scroll;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/AdminPage/AnalyticsPage.css"],"names":[],"mappings":"AAAA,iDAAiD;;AAEjD;IACI,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,cAAc;EAChB;;EAEA;IACE;MACE,kBAAkB;IACpB;EACF","sourcesContent":["/* client/src/pages/AdminPage/AnalyticsPage.css */\r\n\r\n.analytics-page {\r\n    padding: 20px;\r\n  }\r\n  \r\n  .metric {\r\n    background-color: #f9f9f9;\r\n    border-radius: 8px;\r\n    padding: 15px;\r\n    margin-bottom: 20px;\r\n    text-align: center;\r\n  }\r\n  \r\n  .metric h3 {\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .chart-container {\r\n    margin-bottom: 40px;\r\n  }\r\n  \r\n  .message {\r\n    color: red;\r\n    margin: 10px 0;\r\n  }\r\n  \r\n  @media (max-width: 768px) {\r\n    .chart-container {\r\n      overflow-x: scroll;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
