import React, { useState, useEffect, useContext } from "react";
import "./ProfilePage.css";
import { RecentCoursesCard } from "../../components";
import { useNavigate } from "react-router-dom";
import MyCoursesIcon from "../../assets/svg/my-courses.svg";
import LikesIcon from "../../assets/svg/like.svg";
import SettingsIcon from "../../assets/svg/settings.svg";
import ExitIcon from "../../assets/svg/exit.svg";
import { AuthContext } from "../../context/AuthContext";
import { getCourseById, updateUser } from "../../api";
import Modal from 'react-modal';

const ProfilePage = () => {
  const navigate = useNavigate();
  const { user, logout, authToken, setUser } = useContext(AuthContext);

  const [activeMenu, setActiveMenu] = useState("myCourses");
  const [myCoursesData, setMyCoursesData] = useState([]);
  const [likedCoursesData, setLikedCoursesData] = useState([]);
  const [message, setMessage] = useState({ text: '', type: '' });

  // Проверяем, является ли номер гостевым (например, начинается с '999' или '998')
  const isGuestNumber = user && user.phone && (user.phone.startsWith('012') || user.phone.startsWith('998'));

  // Модалка для первого входа (ввод реального номера и нового пароля)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempPhone, setTempPhone] = useState('');
  const [tempPassword, setTempPassword] = useState('');

  // Для смены пароля в настройках (после первого входа)
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    if (user && isGuestNumber) {
      setTempPhone('');
      setTempPassword('');
      setIsModalOpen(true);
    }
  }, [user, isGuestNumber]);

  const handleMenuClick = (menu) => {
    if (menu === "logout") {
      logout();
      navigate("/sign-in");
    } else {
      setActiveMenu(menu);
      setMessage({ text: '', type: '' });
    }
  };

  const handleGoToCourse = (id) => navigate(`/course/${id}`);

  useEffect(() => {
    if (user && user.courses) {
      const fetchMyCourses = async () => {
        const coursesData = await Promise.all(
          user.courses.map((courseId) => getCourseById(courseId))
        );
        setMyCoursesData(coursesData);
      };
      fetchMyCourses();
    }
  }, [user]);

  useEffect(() => {
    if (user && user.likes) {
      const fetchLikedCourses = async () => {
        const coursesData = await Promise.all(
          user.likes.map((courseId) => getCourseById(courseId))
        );
        setLikedCoursesData(coursesData);
      };
      fetchLikedCourses();
    }
  }, [user]);

  const handleSaveData = async () => {
    const phone = tempPhone.trim();
    const password = tempPassword.trim();

    if (!phone) {
      setMessage({ text: 'Введите номер телефона.', type: 'error' });
      return;
    }

    if (!password) {
      setMessage({ text: 'Введите новый пароль.', type: 'error' });
      return;
    }

    try {
      const data = await updateUser(user._id, { phone, password }, authToken);
      setMessage({ text: data.message || 'Данные успешно обновлены.', type: 'success' });
      setUser(data.user);
      setIsModalOpen(false); // Закрываем модалку после успешного обновления
    } catch (error) {
      console.error('Ошибка при обновлении данных:', error);
      setMessage({ text: error.error || 'Ошибка при обновлении данных.', type: 'error' });
    }
  };

  const handleUpdatePassword = async () => {
    const password = newPassword.trim();
    if (!password) {
      setMessage({ text: 'Введите новый пароль.', type: 'error' });
      return;
    }

    try {
      const data = await updateUser(user._id, { password }, authToken);
      setMessage({ text: data.message || 'Пароль успешно обновлен.', type: 'success' });
      setUser(data.user);
      setNewPassword('');
    } catch (error) {
      console.error('Ошибка при обновлении пароля:', error);
      setMessage({ text: error.error || 'Ошибка при обновлении пароля.', type: 'error' });
    }
  };

  return (
    <div className="profile-page">
      <div className="profile-page-left-side">
        {[
          { icon: MyCoursesIcon, title: "Мои курсы", key: "myCourses" },
          { icon: LikesIcon, title: "Лайки", key: "likes" },
          { icon: SettingsIcon, title: "Настройки", key: "settings" },
          { icon: ExitIcon, title: "Выход", key: "logout" },
        ].map((item, index) => (
          <div
            key={index}
            className="menu-item"
            onClick={() => handleMenuClick(item.key)}
            style={{
              fontWeight: activeMenu === item.key ? "bold" : "normal",
              backgroundColor: activeMenu === item.key ? "rgb(255 255 255)" : "transparent",
              cursor: "pointer",
            }}
          >
            <img src={item.icon} alt={item.title} />
            <div
              style={{
                color: item.key === "logout" ? "#FF4A4A" : "black",
                marginLeft: "10px",
              }}
              className="menu-item-title"
            >
              {item.title}
            </div>
          </div>
        ))}
      </div>
      <div className="profile-page-right-side">
        {activeMenu === "myCourses" && (
          <>
            <h2>Мои курсы</h2>
            {myCoursesData.length === 0 ? (
              <p>На данный момент у вас нет приобретенных курсов.</p>
            ) : (
              myCoursesData.map((course) => (
                <RecentCoursesCard
                  key={course._id}
                  img={course.image || ""}
                  title={course.title || "Без названия"}
                  onClick={() => handleGoToCourse(course._id)}
                />
              ))
            )}
          </>
        )}

        {activeMenu === "likes" && (
          <>
            <h2>Лайки</h2>
            {likedCoursesData.length === 0 ? (
              <p>У вас еще нет лайкнутых курсов.</p>
            ) : (
              likedCoursesData.map((course) => (
                <RecentCoursesCard
                  key={course._id}
                  img={course.image || ""}
                  title={course.title || "Без названия"}
                  onClick={() => handleGoToCourse(course._id)}
                />
              ))
            )}
          </>
        )}

        {activeMenu === "settings" && (
          <div className="settings-section">
            <h2>Настройки</h2>
            {message.text && (
              <div className={`message ${message.type}`}>
                {message.text}
              </div>
            )}
            <p>Изменить пароль:</p>
            <div className="form-group">
              <label htmlFor="new-password">Новый пароль:</label>
              <input
                type="password"
                id="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Введите новый пароль"
              />
            </div>
            <button className="save-button" onClick={handleUpdatePassword}>
              Сохранить пароль
            </button>
          </div>
        )}
      </div>

      {/* Модальное окно для первого входа (ввод номера телефона и нового пароля) */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Введите ваш номер телефона и пароль"
        className="modal-content"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <h2>Первый вход</h2>
        <p>Ваш текущий номер временный. Пожалуйста, введите свой реальный номер телефона и задайте пароль.</p>
        <input
          type="tel"
          value={tempPhone}
          onChange={(e) => setTempPhone(e.target.value)}
          placeholder="Введите номер телефона"
          className="phone-input"
        />
        <input
          type="password"
          value={tempPassword}
          onChange={(e) => setTempPassword(e.target.value)}
          placeholder="Введите новый пароль"
          className="phone-input"
          style={{marginTop: '10px'}}
        />
        <button onClick={handleSaveData} className="modal-submit-button" style={{marginTop: '10px'}}>
          Сохранить
        </button>
        <button onClick={() => setIsModalOpen(false)} className="modal-close-button" style={{marginTop: '10px'}}>
          Закрыть
        </button>
      </Modal>
    </div>
  );
};

export default ProfilePage;
