import React, { useEffect, useState } from "react";
import Close from "../../assets/svg/close-thin.svg";
import "./CustomExitModal.css";
import { PhoneInput } from "../../components";
import { useNavigate } from "react-router-dom";

export const CustomExitModal = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Показ кастомного модального окна
      event.preventDefault(); // Блокируем стандартный диалог
      setShowModal(true);
      return "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleSend = () => {
    navigate("/sign-in", { state: { autoRegister: true } }); // Передаём состояние для автоматического открытия регистрации
  };
  
  const handleStay = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content exit">
            <div onClick={handleStay} className="close-modal">
              <img src={Close} alt="" />
            </div>
            <div className="modal-title">
              Два курса бесплатно — начните прямо сейчас!
            </div>
            <div className="modal-inputs">
              <input className="card-gift-input" placeholder="Имя и Фамилия" />
              <input
                className="card-gift-input"
                placeholder="Электронная почта"
              />
              <PhoneInput phone={phone} setPhone={setPhone} />
            </div>
            <button className="btn-modal" onClick={handleSend}>
              Отправить
            </button>
            <div className="card-gift-subtitle">
              Нажимая 'Отправить', вы принимаете условия{" "}
              <a href="/privacy_policy.pdf" download className="privacy-link">
                политики конфиденциальности
              </a>
              .
            </div>
          </div>
        </div>
      )}
    </>
  );
};
